import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import CustomerLoader from "../../CRM/Modal/CustomerLoder";
var jwt = require("jsonwebtoken");

export default function SavingAccountManagement() {
  const navigate = useNavigate();
  let token = localStorage.getItem("SuperAgentChromepayToken");
  let orgType = localStorage.getItem("orgType");
  var decode1 = jwt.decode(token);
  let orgID = decode1.OrganisationID;

  const [custID, setcustID] = useState("");
  const [pageCount, setpageCount] = useState("");
  const [total, settotal] = useState([]);

  let ID = localStorage.getItem("ID");
  const [Capital, setCapital] = useState([]);
  const [DynamicCategory, setDynamicCategory] = useState([]);
  const [SelectedData, setSelectedData] = useState({});
  const limit = 10;
  const [ShowLoader, setShowLoader] = useState(false);

  //   const formsave = (e, page) => {
  //     e.preventDefault();
  //     const data = new FormData(e.target);
  //     const Formvlaues = Object.fromEntries(data.entries());
  //     const formData = Formvlaues;
  //     console.log("Formvlaues === ", Formvlaues);
  //     axios
  //       .post(`/v1/super-Agent/get-super-accounts`, formData, {
  //         headers: { Authorization: `Bearer ${token}` },
  //       })
  //       .then((resp) => {
  //         const data = resp.data.filter;
  //         setCapital(data);
  //         const total = resp.data.totlaRow;
  //         console.log(total);
  //         const totalPage = Math.ceil(total / limit);
  //         setpageCount(totalPage);
  //       });
  //   };

  const SavingsHistoryList = async () => {
    setShowLoader(true);

    try {
      const response = await axios.post(
        `/v1/super-Agent/get-super-accounts`,
        { type: "savings", typeId: SelectedData?._id },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const { data, findDepositCounts, status, msg } = response.data;

      if (status) {
        setCapital(data);
        settotal(findDepositCounts);
        setShowLoader(false);
        const totalPage = Math.ceil(findDepositCounts / limit);
        setpageCount(totalPage);
      } else {
        toast.error(msg);
        setShowLoader(false);
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const getTypesOfSaving = (e) => {
    setShowLoader(true);

    axios
      .post(
        `/v1/super-Agent/get-saving-types`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            setDynamicCategory(data?.data || []);

            let selectedId = data.data?.[0];
            console.log({ selectedId });
            setSelectedData({ _id: selectedId?._id || "" ,type:selectedId?.type });
            setShowLoader(false);
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error("error");
        }
      });
  };

  useEffect(() => {
    SavingsHistoryList();
    getTypesOfSaving();
  }, []);

  useEffect(() => {
    SavingsHistoryList();
  }, [SelectedData]);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = {
      page: page,
      type: "savings",
      typeId: SelectedData?._id,
    };

    setShowLoader(true);

    axios
      .post(`/v1/super-Agent/get-super-accounts`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const { data, findDepositCounts, status, msg } = response.data;

        if (status) {
          setCapital(data);
          settotal(findDepositCounts);
          setShowLoader(false);
          const totalPage = Math.ceil(findDepositCounts / limit);
          setpageCount(totalPage);
        } else {
          toast.error(msg);
          setShowLoader(false);
        }
      });
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  return (
    <>
      <CustomerLoader loader={ShowLoader} />

      <ToastContainer />

      <div className="row g-5 g-xl-8">
        {/* <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        SUPER AGENT CUSTOMERS LIST
                      </span>
                    </div>
                    <form onSubmit={(e) => formsave(e)}>
                      <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                          <div className="col-lg-2 col-sm-4  d-flex align-items-center mb-3 mb-lg-0">
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-phone"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                              </svg>
                            </span>
                            <input
                              type="text"
                              className="form-control form-control-flush flex-grow-1"
                              name="search"
                              placeholder="Search.."
                            />
                          </div>
                          <div className="col-lg-2 col-sm-4 d-flex align-items-center mb-3 mb-lg-0">
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.5"
                                  x="17.0365"
                                  y="15.1223"
                                  width="8.15546"
                                  height="2"
                                  rx="1"
                                  transform="rotate(45 17.0365 15.1223)"
                                  fill="currentColor"
                                />
                                <path
                                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            {orgType === "Union" ? (
                              <>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="digitalrefID"
                                  placeholder="Bruh IDs ref"
                                />
                              </>
                            ) : (
                              <>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="digitalrefID"
                                  placeholder="Union-IDs ref"
                                />
                              </>
                            )}
                          </div>

                          <div className="col-lg-2 col-sm-4  d-flex align-items-center mb-3 mb-lg-0">
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-phone"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                              </svg>
                            </span>
                            <input
                              type="text"
                              className="form-control form-control-flush flex-grow-1"
                              name="phone"
                              placeholder="Contact No"
                            />
                          </div>

                          <div className="col-lg-2  col-sm-4 d-flex align-items-center mb-5 mb-lg-0">
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="2"
                                  y="2"
                                  width="9"
                                  height="9"
                                  rx="2"
                                  fill="currentColor"
                                />
                                <rect
                                  opacity="0.3"
                                  x="13"
                                  y="2"
                                  width="9"
                                  height="9"
                                  rx="2"
                                  fill="currentColor"
                                />
                                <rect
                                  opacity="0.3"
                                  x="13"
                                  y="13"
                                  width="9"
                                  height="9"
                                  rx="2"
                                  fill="currentColor"
                                />
                                <rect
                                  opacity="0.3"
                                  x="2"
                                  y="13"
                                  width="9"
                                  height="9"
                                  rx="2"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            <select
                              className="form-select border-0 flex-grow-1"
                              name="status"
                              data-control="select2"
                              data-placeholder="Category"
                              data-hide-search="true"
                            >
                              <option defaultValue="" selected disabled>
                                Status
                              </option>

                              <option defaultValue="verified">Verified</option>
                              <option defaultValue="pending">pending</option>
                            </select>
                          </div>

                          <div className="col-lg-2 col-sm-4   d-flex input-container align-items-center mb-3  mb-lg-0">
                            <label className="small_label">Start Date</label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="fromDate"
                              placeholder="from Date.."
                            />
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                          </div>

                          <div className="col-lg-2 col-sm-4   d-flex align-items-center mb-3 input-container mb-lg-0">
                            <label className="small_label">End Date</label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="toDate"
                              placeholder="to Date.."
                            />
                          </div>
                        </div>

                        <div className="min-w-175px d-flex gap-1">
                          <button
                            type="submit"
                            className="btn btn-dark  mx-sm-3"
                            id="kt_advanced_search_button_1"
                          >
                            Search
                          </button>
                          <button
                            type="reset"
                            onClick={SavingAccountManagementHistoryList}
                            className="btn btn-secondary"
                            id="kt_advanced_search_button_1"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div> */}

        <div className="col-lg-12">
          <div
            className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
          bgi-size-cover bg_card"
          >
            <div className="card-body flex-column p-5">
              <div className="card-rounded  col-12 d-flex flex-stack flex-wrap p-5 pb-0">
                <ul
                  className="nav flex-wrap border-transparent fw-bold"
                  role="tablist"
                >
                  {DynamicCategory?.map((item) => (
                    <li
                      onClick={() => setSelectedData(item)}
                      className="nav-item my-1"
                      role="presentation"
                    >
                      <a
                        className={
                          item?._id == SelectedData?._id
                            ? "btn btn-color-gray-600 btn-active-secondary  active btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                            : "btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                        }
                      >
                        {item?.type}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div class="card-header border-0 pt-6 d-flex align-items-center">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1 text-capitalize ">
                 {SelectedData?.type} List
                </span>
                {/* <span className="text-muted mt-1 fw-semibold fs-7"> Over {total} Customer</span> */}
              </h3>
              <div class="card-title"></div>
            </div>

            <div className="card-body py-3">
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fw-bold text-muted th-title">
                      <th className="min-w-150px">Amount</th>
                      <th className="min-w-150px"> Limit</th>
                      <th className="min-w-150px"> Interest Rate</th>
                      <th className="min-w-150px"> Amount With Interest Amount</th>
                      <th className="min-w-200px">Total Interest Received</th>

                      <th className="min-w-200px ">Next Interest Calculation Date</th>
                      <th className="min-w-100px ">Deposit Date</th>
                    </tr>
                  </thead>

                  <tbody>
                    {Capital?.map((item) => (
                      <tr>
                     
                        <td className="text-center">{item?.amount?.toFixed(2)}</td>
                        <td className="text-center">{item?.limit?.toFixed(2)}</td>
                        <td className="text-center">{item?.interestRate}</td>
                        <td className="text-center">{item?.AmountWithInterestAmount?.toFixed(2)}</td>
                        <td className="text-center">
                          {item?.totalInterestReceived?.toFixed(2)}
                        </td>
                 

                        <td className="text-center">
                          {" "}
                          {Moment(item?.nextInterestCalculationDate).format("DD/MM//YYYY")}
                        </td>
                        <td className="text-center">
                          {" "}
                          {Moment(item?.depositeDate).format("DD/MM//YYYY")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="col-lg-12 mt-2 text-end">
                <ReactPaginate
                  PreviousLabel={"Previous"}
                  NextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-end gap-2"}
                  pageClassName={"page-item mx-2"}
                  pageLinkClassName={"page-link"}
                  PreviousClassName={"page-item"}
                  PreviousLinkClassName={"page-link pagestyle"}
                  NextClassName={"page-item"}
                  NextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

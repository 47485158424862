import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import CustomerLoader from "../../../CRM/Modal/CustomerLoder";
var jwt = require("jsonwebtoken");

export default function CapitalLoan() {
  const navigate = useNavigate();
  let token = localStorage.getItem("SuperAgentChromepayToken");
  let orgType = localStorage.getItem("orgType");
  var decode1 = jwt.decode(token);
  let orgID = decode1.OrganisationID;

  const [custID, setcustID] = useState("");
  const [pageCount, setpageCount] = useState("");
  const [total, settotal] = useState([]);

  let ID = localStorage.getItem("ID");
  const [Capital, setCapital] = useState([]);
  const limit = 10;
  const [ShowLoader, setShowLoader] = useState(false);

  //   const formsave = (e, page) => {
  //     e.preventDefault();
  //     const data = new FormData(e.target);
  //     const Formvlaues = Object.fromEntries(data.entries());
  //     const formData = Formvlaues;
  //     console.log("Formvlaues === ", Formvlaues);
  //     axios
  //       .post(`/v1/super-Agent/get-super-accounts`, formData, {
  //         headers: { Authorization: `Bearer ${token}` },
  //       })
  //       .then((resp) => {
  //         const data = resp.data.filter;
  //         setCapital(data);
  //         const total = resp.data.totlaRow;
  //         console.log(total);
  //         const totalPage = Math.ceil(total / limit);
  //         setpageCount(totalPage);
  //       });
  //   };

  const CapitallyHistoryList = async () => {
    setShowLoader(true);

    try {
      const response = await axios.post(
        `/v1/super-Agent/get-super-accounts`,
        { type: "Loans" },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const { data, totalDisPage, status, msg } = response.data;

      if (status) {
        setCapital(data);
        settotal(totalDisPage);
        setShowLoader(false);
        const totalPage = Math.ceil(totalDisPage / limit);
        setpageCount(totalPage);
      } else {
        toast.error(msg);
        setShowLoader(false);
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    CapitallyHistoryList();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page ,type: "Loans" };
    setShowLoader(true);

    axios
      .post(`/v1/super-Agent/get-super-accounts`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const { data, totalDisPage, status, msg } = response.data;

        if (status) {
          setCapital(data);
          settotal(totalDisPage);
          setShowLoader(false);
          const totalPage = Math.ceil(totalDisPage / limit);
          setpageCount(totalPage);
        } else {
          toast.error(msg);
          setShowLoader(false);
        }
      });
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  return (
    <>
      <CustomerLoader loader={ShowLoader} />

      <ToastContainer />

      <div className="row g-5 g-xl-8">
        {/* <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        SUPER AGENT CUSTOMERS LIST
                      </span>
                    </div>
                    <form onSubmit={(e) => formsave(e)}>
                      <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                          <div className="col-lg-2 col-sm-4  d-flex align-items-center mb-3 mb-lg-0">
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-phone"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                              </svg>
                            </span>
                            <input
                              type="text"
                              className="form-control form-control-flush flex-grow-1"
                              name="search"
                              placeholder="Search.."
                            />
                          </div>
                          <div className="col-lg-2 col-sm-4 d-flex align-items-center mb-3 mb-lg-0">
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.5"
                                  x="17.0365"
                                  y="15.1223"
                                  width="8.15546"
                                  height="2"
                                  rx="1"
                                  transform="rotate(45 17.0365 15.1223)"
                                  fill="currentColor"
                                />
                                <path
                                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            {orgType === "Union" ? (
                              <>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="digitalrefID"
                                  placeholder="Bruh IDs ref"
                                />
                              </>
                            ) : (
                              <>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="digitalrefID"
                                  placeholder="Union-IDs ref"
                                />
                              </>
                            )}
                          </div>

                          <div className="col-lg-2 col-sm-4  d-flex align-items-center mb-3 mb-lg-0">
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-phone"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                              </svg>
                            </span>
                            <input
                              type="text"
                              className="form-control form-control-flush flex-grow-1"
                              name="phone"
                              placeholder="Contact No"
                            />
                          </div>

                          <div className="col-lg-2  col-sm-4 d-flex align-items-center mb-5 mb-lg-0">
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="2"
                                  y="2"
                                  width="9"
                                  height="9"
                                  rx="2"
                                  fill="currentColor"
                                />
                                <rect
                                  opacity="0.3"
                                  x="13"
                                  y="2"
                                  width="9"
                                  height="9"
                                  rx="2"
                                  fill="currentColor"
                                />
                                <rect
                                  opacity="0.3"
                                  x="13"
                                  y="13"
                                  width="9"
                                  height="9"
                                  rx="2"
                                  fill="currentColor"
                                />
                                <rect
                                  opacity="0.3"
                                  x="2"
                                  y="13"
                                  width="9"
                                  height="9"
                                  rx="2"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            <select
                              className="form-select border-0 flex-grow-1"
                              name="status"
                              data-control="select2"
                              data-placeholder="Category"
                              data-hide-search="true"
                            >
                              <option defaultValue="" selected disabled>
                                Status
                              </option>

                              <option defaultValue="verified">Verified</option>
                              <option defaultValue="pending">pending</option>
                            </select>
                          </div>

                          <div className="col-lg-2 col-sm-4   d-flex input-container align-items-center mb-3  mb-lg-0">
                            <label className="small_label">Start Date</label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="fromDate"
                              placeholder="from Date.."
                            />
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                          </div>

                          <div className="col-lg-2 col-sm-4   d-flex align-items-center mb-3 input-container mb-lg-0">
                            <label className="small_label">End Date</label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="toDate"
                              placeholder="to Date.."
                            />
                          </div>
                        </div>

                        <div className="min-w-175px d-flex gap-1">
                          <button
                            type="submit"
                            className="btn btn-dark  mx-sm-3"
                            id="kt_advanced_search_button_1"
                          >
                            Search
                          </button>
                          <button
                            type="reset"
                            onClick={CapitalloanHistoryList}
                            className="btn btn-secondary"
                            id="kt_advanced_search_button_1"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div> */}

        <div className="col-lg-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div class="card-header border-0 pt-6 d-flex align-items-center">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                 CAPITAL LOAN CUSTOMERS LIST
                </span>
                {/* <span className="text-muted mt-1 fw-semibold fs-7"> Over {total} Customer</span> */}
              </h3>
              <div class="card-title"></div>
            </div>

            <div className="card-body py-3">
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fw-bold text-muted th-title">
                      <th className="min-w-300px">ID Photo</th>
                      <th className="min-w-150px"> Monthly Income</th>
                      <th className="min-w-150px"> Loan Amount</th>
                      <th className="min-w-150px"> Interest Rate</th>
                      <th className="min-w-150px"> Repayment Schedule</th>
                      <th className="min-w-100px">Loan Duration</th>

                      <th className="min-w-100px ">Approved Date</th>
                    </tr>
                  </thead>

                  <tbody>
                    {Capital?.map((item) => (
                      <tr>
                        <td>
                          {" "}
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-45px me-5">
                              <img
                                src={
                                  item.IDphoto ||
                                  "/assets_new/images/lodingwallet.gif"
                                }
                                alt=""
                              />
                            </div>
                            <div class="d-flex justify-content-start flex-column">
                              <a
                                //   href={`/org-customer-view/${item._id}`}
                                class="usertitle Acolor mb-2"
                              >
                                {" "}
                                {item.fullName}
                              </a>
                              <span class="font-icon mb-1">
                                <span class="">
                                  <i class="fal fa-phone-volume"></i>
                                </span>
                                +{item.phoneNumber}
                              </span>

                              <span class="font-icon mb-1">
                                <span class="">
                                  <i class="fal fa-envelope"></i>
                                </span>
                                {item.email}{" "}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">{item.monthlyIncome}</td>
                        <td className="text-center">{item.loanAmount}</td>
                        <td className="text-center">{item.interestRate}</td>
                        <td className="text-center">{item.repaymentSchedule}</td>
                        <td className="text-center">{item.loanDuration}</td>

                        <td className="text-center">
                          {" "}
                          {Moment(item.approvedDate).format("DD/MM//YYYY")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="col-lg-12 mt-2 text-end">
                <ReactPaginate
                  PreviousLabel={"Previous"}
                  NextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-end gap-2"}
                  pageClassName={"page-item mx-2"}
                  pageLinkClassName={"page-link"}
                  PreviousClassName={"page-item"}
                  PreviousLinkClassName={"page-link pagestyle"}
                  NextClassName={"page-item"}
                  NextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

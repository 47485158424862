import React from "react";
import { useState, useRef, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import axios from "axios";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrgVerifyModel from "../../../OrgShareManagement/Componets/OrgVerifyModel";
import CustomerLoader from "../../../../SuperAgent/CRM/Modal/CustomerLoder";

export default function AddSuperAgentSavingbyOrgComponets({
  _id,
  userDetail,
  handleReloadData,
}) {
  let token = localStorage.getItem("organizationToken");

  const [savingsHistory, setSavingsHistory] = useState([]);
  const [ShowModel, setShowModel] = useState(false);
  const [ShowCancelModel, setShowCancelModel] = useState(false);
  const [ShowLoader, setShowLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [transId, setTransId] = useState("");
  const [WithAmount, setWithAmount] = useState("");
  const [TypeOfSavings, setTypeOfSavings] = useState("");
  const [NumberOfsavings, setNuberOfSavings] = useState("");
  const [filterData, setfilterData] = useState("all");

  const limit = 10;

  const interestRatecount = async (e) => {
    axios
      .post(
        `/v1/org/CRM/get-savings`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        let data = resp?.data?.data;
        if (resp.status) {
          setNuberOfSavings(data);
        } else {
          toast.error(data.msg);
        }
      });
  };

  const addSavingHistory = (e) => {
    axios
      .post(
        `/v1/org/CRM/get-saving-trans`,
        {
          agentId: _id,
          page: 1,
          savingType: filterData,
          type: "deposite",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setSavingsHistory(saveData);
        const total = resp.data.totalRaw;

        const totalPage = Math.ceil(total / limit);

        console.log({ totalPage });
        setPage(totalPage);
      })
      .catch((error) => error);
  };

  useEffect(() => {
    interestRatecount();
    addSavingHistory();
  }, []);

  useEffect(() => {
    addSavingHistory();
  }, [filterData]);

  const fetchCommentate = async (page) => {
    axios
      .post(
        `/v1/org/CRM/get-saving-trans`,
        {
          agentId: _id,
          page: page,
          type: "deposite",
          savingType: filterData,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setSavingsHistory(saveData);
        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setPage(totalPage || 0);
      });
  };

  const handleChange = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchCommentate(page);
  };

  const AddSavingrequired = (e) => {
    e.preventDefault();
    setShowModel(true);
  };

  const AddSavingFormData = async (e) => {
    const formData = {
      agentId: _id,
      type: TypeOfSavings,
      interestRate:
        TypeOfSavings === "mandatory"
          ? NumberOfsavings?.mandatory
          : NumberOfsavings?.voluantary,
      amount: WithAmount,
    };

    try {
      const resp = await axios.post(
        `/v1/org/CRM/add-superagent-saving`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setWithAmount("");
      setTypeOfSavings("");
      setShowLoader(false);

      if (resp.status) {
        const data = resp.data;
        if (data.status) {
          toast.success(data.msg);
          addSavingHistory();
          handleReloadData();
        } else {
          toast.error(data.msg);
        }
      } else {
        toast.error("Unexpected response status");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while processing your request.");
      setShowLoader(false);
    }
  };

  const Transitioncancel = (item) => {
    let _id = item?._id;
    console.log({ _id });
    if (item?.status == "cancel") {
      toast.error("This transaction has already been canceled.");
    } else {
      setTransId(_id);
      setShowCancelModel(true);
    }
  };

  const handleCancelTransaction = async () => {
    setShowLoader(true);
    try {
      const response = await axios.post(
        `/v1/org/CRM/cancel-super-saving-transaction`,
        { savingId: transId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.status) {
        toast.success(response.data.msg);
        setShowCancelModel(false);
        setShowLoader(false);
        addSavingHistory();
        handleReloadData();
      } else {
        toast.error(response.data.msg);
        setShowCancelModel(false);
        setShowLoader(false);
      }
    } catch (error) {}
  };

  const DownloadInvoice = (invoiceData) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();
    console.log({ invoiceData, userDetail });

    // Set up styling for the PDF
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    const width = doc.internal.pageSize.getWidth();

    // Calculate the width of the text for the invoice heading
    const headingTextWidth =
      (doc.getStringUnitWidth("Your Saving Deposit Invoice") *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (width - headingTextWidth) / 2;

    // Add the invoice heading
    doc.text("Your Withdrawal Deposit Invoice", headingX, 20);

    // Format the date as dd mm yyyy
    const formattedDate = new Date(invoiceData.activityDate).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }
    );

    // Add the date on the right side
    const dateTextWidth =
      (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Date: ${formattedDate}`, dateX, 40);

    // Set up styling for the section headings
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);

    // Add the customer details heading with left alignment
    doc.text("Customer Details", 10, 60);

    // Add the customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Customer ID: ${userDetail._id}`, 10, 80);
    doc.text(
      `Customer Name: ${userDetail.firstName} ${userDetail.lastName} `,
      10,
      90
    );
    doc.text(`Customer Phone: ${userDetail.phone}`, 10, 100);
    doc.text(`Status: ${invoiceData.type}`, 10, 110);
    // Add the saving details heading with left alignment
    doc.text("Saving Details", 10, 120);
    // Add the share details table
    if (invoiceData.type == "mandatory") {
      doc.autoTable({
        startY: 140,
        head: [
          [
            "Share ID",
            "Saving ID",
            "Share Price (ETB)",
            "Saving Interest %",
            "Saving Type",
          ],
        ],
        body: [
          [
            invoiceData.shareId,
            invoiceData._id,
            ` ${invoiceData.shareAm}`,
            `${invoiceData.interestRate || 0}`,
            invoiceData.type,
          ],
        ],
      });

      // Add the total share amount and total saving amount on the right side in a smaller font size
      const totalTextSize = 10;
      const totalX = width - 70; // Adjust as needed
      doc.setFontSize(totalTextSize);
      doc.text(
        `Total Share Amount (ETB):  ${invoiceData.shareAmount || 0}`,
        totalX,
        doc.autoTable.Previous.finalY + 10
      );
      doc.text(
        `Total Saving Amount (ETB):  ${invoiceData.amount}`,
        totalX,
        doc.autoTable.Previous.finalY + 20
      );
    } else {
      doc.autoTable({
        startY: 140,
        head: [["Saving ID", "Saving Interest %", "Saving Type"]],
        body: [
          [invoiceData._id, `${invoiceData.interestRate || 0}`, "Voluntary"],
        ],
      });

      // Add the total saving amount on the right side in a smaller font size
      const totalTextSize = 10;
      const totalX = width - 70; // Adjust as needed
      doc.setFontSize(totalTextSize);
      doc.text(
        `Total Saving Amount (ETB):  ${invoiceData.amount}`,
        totalX,
        doc.autoTable.Previous.finalY + 20
      );
    }
    // Save the PDF and initiate download
    doc.save("invoice.pdf");
  };

  return (
    <>
      <ToastContainer />

      <CustomerLoader loader={ShowLoader} />

      <div className="row">
        <div className="col-lg-6">
          <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div className="card-header cursor-pointer">
              <div className="card-body p-9">
                <div
                  id="kt_account_settings_profile_details"
                  className="collapse show"
                >
                  <form
                    // add saving
                    onSubmit={(e) => AddSavingrequired(e)}
                    id="kt_account_profile_details_form"
                    className="form"
                  >
                    <div className="card-body border-top p-9">
                      <div className="row mb-8">
                        <div className="col-lg-12 mb-8">
                          <div className="row">
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                              Amount (ETB)
                            </label>
                            <div className="col-lg-8 fv-row">
                              <input
                                type="number"
                                name="amount"
                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                placeholder="Amount"
                                step="any"
                                required
                                min={1}
                                value={WithAmount}
                                onChange={(e) => {
                                  let inputValue = e.target.value;
                                  {
                                    setWithAmount(parseFloat(inputValue));
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 mb-8">
                          <div className="row">
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                              Type of Saving
                            </label>
                            <div className="col-lg-8 fv-row">
                              <select
                                name="type"
                                required
                                value={TypeOfSavings}
                                onChange={(e) =>
                                  setTypeOfSavings(e.target.value)
                                }
                                className="form-select  flex-grow-1"
                                data-placeholder="Please Select"
                              >
                                <option value="">Select Withdraw Type</option>

                                <option value="mandatory">Mandatory</option>
                                <option value="voluntary">Voluntary</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <>
                          {TypeOfSavings == "mandatory" && (
                            <>
                              <div className="col-lg-12 mb-8">
                                <div className="row">
                                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                    Share Amount (ETB)
                                  </label>
                                  <div className="col-lg-8 fv-row">
                                    <input
                                      type="number"
                                      name="amount"
                                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                      placeholder="Amount"
                                      step="any"
                                      required
                                      value={(WithAmount * 20) / 100}
                                      onChange={(e) => {
                                        let inputValue = e.target.value;

                                        setWithAmount(parseFloat(inputValue));
                                      }}
                                      min={0}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 mb-8">
                                <div className="row">
                                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                    Total Saving Amount (ETB)
                                  </label>
                                  <div className="col-lg-8 fv-row">
                                    <input
                                      type="number"
                                      name="amount"
                                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                      placeholder="Amount"
                                      required
                                      step="any"
                                      value={
                                        WithAmount - (WithAmount * 20) / 100
                                      }
                                      onChange={(e) => {
                                        let inputValue = e.target.value;

                                        setWithAmount(parseFloat(inputValue));
                                      }}
                                      min={0}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>

                        <div className="row">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Interest Rate
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="interest"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Interest Rate"
                              step="any"
                              value={
                                TypeOfSavings == "mandatory"
                                  ? NumberOfsavings?.mandatory
                                  : NumberOfsavings?.voluantary || 0
                              }
                              readOnly
                            />
                          </div>
                        </div>
                      </div>

                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-light btn-active-light-primary me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="cardlabel fw-bold fs-3 mb-1">
                  Recent Transactions
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7"></span>
              </h3>
              <h3 className="card-title align-items-start flex-column">
                <div className="row">
                  <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                    <div className="d-flex">
                      <select
                        name="marriageStatus"
                        className="form-select  flex-grow-1"
                        data-placeholder="Please Select"
                        onChange={(e) => setfilterData(e.target.value)}
                      >
                        <option value={"all"}>All</option>
                        <option value={"voluntary"}>Voluntary</option>
                        <option value={"mandatory"}>Mandatory</option>
                      </select>
                    </div>
                  </div>
                </div>
              </h3>
              <div className="text-end">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn  btn-sm btn-SUCCESS "
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText={
                    <>
                      <i className="fad fa-download fs-1"></i>
                    </>
                  }
                />
              </div>
            </div>

            <div className="card-body py-3">
              <div className="table-responsive">
                <table
                  id="table-to-xls"
                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                >
                  <thead>
                    <tr className="fw-bold text-muted th-title">
                      <th className="min-w-125px">
                        Transaction <br /> Date{" "}
                      </th>
                      <th className="min-w-150px">
                        Total
                        <br />
                        Amount (ETB)
                      </th>

                      <th className="min-w-150px">
                        Saving <br /> Type{" "}
                      </th>
                      <th className="min-w-100px">
                        Interest
                        <br /> Rate{" "}
                      </th>
                      {/* <th className="min-w-100px">Type</th> */}
                      <th className="min-w-100px ">
                        Status <br />
                      </th>
                      <th className="min-w-100px ">
                        Cancel <br />
                      </th>
                      <th className="min-w-100px ">
                        Invoice <br />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {savingsHistory.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {moment(item.createdAt).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </td>
                        <td>{item?.amount}</td>

                        <td>
                          {item.type == "mandatory" ? "Mandatory" : "Voluntary"}
                        </td>
                        <td>{item?.interestRate.toFixed(2)}</td>

                        <td>
                          {item.status == "cancel" ? (
                            <span className="text-danger">Cancelled</span>
                          ) : (
                            <span className="text-success">Success</span>
                          )}
                        </td>
                        <td>
                          <button
                            className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-trigger="hover"
                            title="Cancel"
                            onClick={(e) => {
                              Transitioncancel(item);
                            }}
                          >
                            <span className="svg-icon svg-icon-3">
                              <img
                                src="/assets_new/images/cross.png"
                                width="20px"
                              />
                            </span>
                          </button>
                        </td>
                        <td className="text-center">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => DownloadInvoice(item)}
                          >
                            Download Invoice
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-lg-12 mt-2 text-end">
                <ReactPaginate
                  PreviousLabel={"Previous"}
                  NextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handleChange}
                  containerClassName={"pagination justify-content-end gap-sm-3"}
                  pageClassName={"page-item mx-2"}
                  pageLinkClassName={"page-link"}
                  PreviousClassName={"page-item"}
                  PreviousLinkClassName={"page-link pagestyle"}
                  NextClassName={"page-item"}
                  NextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>

        <OrgVerifyModel
          ShowModel={ShowModel}
          setShowModel={setShowModel}
          handleSubmit={AddSavingFormData}
        />
        <OrgVerifyModel
          ShowModel={ShowCancelModel}
          setShowModel={setShowCancelModel}
          handleSubmit={handleCancelTransaction}
        />
      </div>
    </>
  );
}

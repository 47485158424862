//import React from "react";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios, { Axios } from "axios";
//import Cookies from 'js-cookie';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
///Login
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
var jwt = require("jsonwebtoken");

export default function OrgLogin({hadlerorg}) {

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  useEffect(() => {
    hadlerorg();
    const auth = localStorage.getItem("organizationToken");
    if (auth) {
      ///  history.push("/organization")
    }
    document.body.className = "header-none header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled";
    return () => {
        document.body.className = "header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled";
       }
  });

  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);

  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          
          // Update state with the user's location
          setLocation({ latitude: lat, longitude: lon, address: '' });
          setError(null); // Clear any previous errors

          // Fetch address using reverse geocoding
          try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`);
            const data = await response.json();
            const address = data.address ? `${data.address.city || ''}, ${data.address.country || ''}` : 'Address not found';
            setLocation(prevLocation => ({ ...prevLocation, address }));
          } catch (error) {
            setError('Failed to fetch address.');
          }
        },
        (err) => {
          // Update state with the error message
          setError(err.message);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }}

  // Use useEffect to fetch the location when the component mounts
  useEffect(() => {
    fetchLocation();
  }, []);

  //let history = useHistory(toast);

  const login = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    axios.post(`/v1/org/auth/Login`, {...formData, location}).then((res) => {
      let result = res.data;
      if (result) {
        let data = result;
        console.log(data);
        if (data.status) {
          localStorage.setItem("ID", result.ID);
          localStorage.setItem("organizationToken", result.token);
          localStorage.setItem("proof", result.prof);
          localStorage.setItem('orgAgentType', result.orgType);
          axios.post(`/v1/org/reports/org-detail`,{},{  headers:{"Authorization":`Bearer ${result.token}`}}).then((resp) => {
           const data = resp.data.data;
           localStorage.setItem('org-image', data.image);
           localStorage.setItem('org-name', data.name);
           localStorage.setItem('org-email', data.email);
        
           console.log("@@@@@", data);
         });
          //window.location = "/organization";
           navigate("/organization");
          // history.push("/organization");
          toast.success(data.msg);
        } else {
          toast.error(data.msg);
        }
      } else {
        toast.error(data.msg);
      }
    });
  };

  function handleShow() {
    setShow(true);
  }

  const AddFormData = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("organizationToken");
    var decode1 = jwt.decode(token);
    // let agentID = decode1.agentID;

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);

    axios.post(`/v1/org/auth/Forgot-Pass`, formData).then((resp) => {
      const data = resp.data;
      console.log(data);
      if (data.status) {
        toast.success(data.msg);
        setShow1(true);
      } else {
        toast.error(data.msg);
      }
    });
  };

    
 




  ///-------------------------------------////

  const AddFormData1 = async (e) => {
    e.preventDefault();
    let token = localStorage.getItem("organizationToken");
    var decode1 = jwt.decode(token);


    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);

    axios.post(`/v1/org/auth/Forgot-Pass-OTP`, formData).then((resp) => {
      const data = resp.data;
      console.log(data);
      if (data.status) {
        toast.success(data.msg);
      } else {
        toast.error(data.msg);
      }
    });
  };

  const formhandle = () => {
    setShow(false);
    setShow1(true);
  };
  return (
    <>
      <ToastContainer />
      <div className="content main-login">
        <div className="container">
          <div className="row">
            <div className="col-md-6 contents">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="mb-4 text-center">
                    <img
                      src="/assets_new/images/logo.png"
                      alt="logo"
                      width="50px"
                      className="mb-4"
                    />
                    <h3> Sign In </h3>
                  </div>
                  <form onSubmit={login}>
                    <div className="form-group first">
                      <label for="username">Username</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        id="username"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group last mb-4">
                      <label for="Forgot Password"> Password</label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="password"
                        onChange={(e) => setPass(e.target.value)}
                      />
                    </div>
                    <div className="d-flex mb-5 align-items-center">
                      <label className="control control--checkbox mb-0">
                        <span className="caption">Remember me</span>
                        <input type="checkbox" checked />
                        <div className="control__indicator"></div>
                      </label>
                      <span className="ml-auto">
                        <h6>
                          <a href="#" onClick={() => handleShow()}>
                          Forgot Password
                          </a>
                        </h6>
                      </span>
                    </div>
                    <input
                      type="submit"
                      value="Log In"
                      className="btn btn-block btn-primary mt-5"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Recover Password </Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData(e)}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <label className="mb-2">Email</label>
              <input
                className="form-control"
                placeholder="Enter your email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={() =>[ formhandle()]}
            >
              {" "}
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* //--------------------------- */}
      <Modal show={show1} onHide={() => setShow1(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reset password</Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData1(e)}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <label className="mb-2">Email</label>
              <input
                className="form-control"
                placeholder="Enter your email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="col-lg-12 mt-3 ">
              <label className="mb-2" style={{ float: "left" }}>
                New Password
              </label>
              <input
                className="form-control"
                type="password"
                data-toggle="password"
                placeholder="Enter your password"
                name="newPassword"
              />
            </div>
            <div className="col-lg-12 mt-3 ">
              <label className="mb-2" style={{ float: "left" }}>
                {" "}
                Confirm -Password
              </label>
              <input
                className="form-control"
                type="password"
                data-toggle="password"
                placeholder="Enter your password"
                name="confirmPassword"
              />
            </div>
            <div className="col-lg-12 mt-3 ">
              <label className="mb-2" style={{ float: "left" }}>
                OTP
              </label>
              <input
                className="form-control"
                type="password"
                placeholder="Enter your password"
                name="otp"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow1(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={() => setShow1(false)}
            >
              {" "}
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

import React, { useState } from 'react';

const AskToAI = () => {
  const [question, setQuestion] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Add the user's question to the chat history
    setChatHistory((prevHistory) => [...prevHistory, { role: 'user', content: question }]);
    setIsTyping(true); // Show typing indicator

    // Make API call to get the AI response
    try {
      const response = await fetch('http://localhost:2010/ask-to-openai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer your-token-here',
        },
        body: JSON.stringify({ question }),
      });

      const data = await response.json();

      if (data.status) {
        // Add the AI response to the chat history, preserving line breaks
        setChatHistory((prevHistory) => [
          ...prevHistory,
          { role: 'assistant', content: data.answer.split('\n') }, // Split response by line breaks
        ]);
      } else {
        alert('Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to get a response from AI. Please check your connection.');
    } finally {
      setIsTyping(false); // Hide typing indicator
    }

    // Clear the input field
    setQuestion('');
  };

  return (
    <div style={styles.container}>
      <div style={styles.chatBox}>
        {chatHistory.map((chat, index) => (
          <div
            key={index}
            style={chat.role === 'user' ? styles.userMessageContainer : styles.aiMessageContainer}
          >
            <div style={chat.role === 'user' ? styles.userMessage : styles.aiMessage}>
              {/* Render each line as a separate div */}
              {Array.isArray(chat.content) ? (
                chat.content.map((line, i) => (
                  <div key={i}>{line}</div>
                ))
              ) : (
                <div>{chat.content}</div>
              )}
            </div>
          </div>
        ))}
        {isTyping && (
          <div style={styles.aiMessageContainer}>
            <div style={styles.typingIndicator}>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit} style={styles.form}>
        <input
          type="text"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Ask your question..."
          style={styles.input}
          required
        />
        <button type="submit" style={styles.button}>Send</button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f2f2f2',
    padding: '20px',
  },
  chatBox: {
    width: '100%',
    maxWidth: '800px',
    height: '65vh',
    overflowY: 'scroll',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    backgroundColor: '#ffffff',
    marginBottom: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  userMessageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '10px',
  },
  aiMessageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  userMessage: {
    backgroundColor: '#63a9b4',
    color: 'white',
    padding: '12px',
    borderRadius: '8px',
    maxWidth: '60%',
    textAlign: 'left',
    fontSize: '16px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
  },
  aiMessage: {
    backgroundColor: '#e2e2e2',
    color: '#333',
    padding: '12px',
    borderRadius: '8px',
    maxWidth: '60%',
    textAlign: 'left',
    fontSize: '16px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
  },
  typingIndicator: {
    backgroundColor: '#e2e2e2',
    color: '#333',
    padding: '12px',
    borderRadius: '8px',
    maxWidth: '60%',
    textAlign: 'left',
    fontSize: '16px',
    display: 'flex',
    gap: '5px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
  },
  form: {
    display: 'flex',
    width: '100%',
    maxWidth: '800px',
  },
  input: {
    flex: 1,
    padding: '12px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    marginRight: '10px',
    fontSize: '16px',
  },
  button: {
    padding: '12px 20px',
    backgroundColor: '#63a9b4',
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
  },
};

export default AskToAI;

import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomerLoader from "../../../SuperAgent/CRM/Modal/CustomerLoder";
import OrgVerifyModel from "../../OrgShareManagement/Componets/OrgVerifyModel";


export default function OrgAddProductManagement({ hadlerorg }) {
  let token = localStorage.getItem("organizationToken");
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [formData, setFormData] = useState();
  const [ShowPassword, setShowPassword] = useState(false);
  const [ShowLoader, setShowLoader] = useState(false);
  const [unitData, setData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  
  const unitlist = async () => {
    await axios
      .post(`/v1/super-Agent/unit-list`, {limit : 100}, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
        console.log("pass" + data);
        const total = resp.data.totalRow;
        // console.log(total);
        const totalPage = Math.ceil(total / 100);
      });
  };


  const handleFileChange = (data) => {
    let formdata = new FormData();
    // console.log("@#@#@123" + data);

    if (data) {
      const url = URL.createObjectURL(data);
      setImageUrl(url);
      formdata.append("file", data);

      console.log("image first data", formdata);
      formdata.append("Image", data);
      axios
        .post(`/v1/DID/ImageUploader`, formdata, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const imgurl = res.data.data;
          console.log("image url", imgurl);
          //   setCutsmerImage(imgurl);
          setFormData({
            ...formData,
            image: imgurl,
          });
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    } else {
      toast.error(data.msg);
    }
    // })
    // .catch(error => {
    //   console.error("Error checking face API:", error);
    // });
    // } else {

    // }
  };

  const formcheck = (e) => {
    e.preventDefault();
    setShowPassword(true);
  };

  const AddFormData = async (e) => {


    axios
      .post(`/v1/org/CRM/org-add-product`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((resp) => {
        let data = resp.data;
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            toast.success(data.msg);
            navigate("/Org/add/products");
            e.target.reset();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      });
  };



  useEffect(() => {
    hadlerorg();
    unitlist()
  }, []);
  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Add Products</h3>
                    </div>
                    <div className="card-title m-0">
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="btn btn-dark"
                        id="kt_advanced_search_button_1"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => formcheck(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="row mb-8">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Product Image
                          </label>
                          <div className="col-lg-8">
                            <div
                              className="image-input image-input-outline"
                              data-kt-image-input="true"
                            >
                              <div className="image-input-wrapper w-125px h-125px"></div>
                              <div style={{ position: "absolute", top: "0px" }}>
                                {imageUrl && (
                                  <img
                                    src={imageUrl}
                                    alt="Uploaded"
                                    className="w-125px h-125px"
                                  />
                                )}
                              </div>
                              <label
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="change"
                                data-bs-toggle="tooltip"
                                title="Product Image"
                              >
                                <i className="bi bi-pencil-fill fs-7"></i>
                                <input
                                  type="file"
                                  name="IDphoto"
                                  onChange={(e) =>
                                    handleFileChange(e.target.files[0])
                                  }
                                />
                              </label>
                              <span
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="cancel"
                                data-bs-toggle="tooltip"
                                title="Cancel avatar"
                              >
                                <i className="bi bi-x fs-2"></i>
                              </span>
                              <span
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="remove"
                                data-bs-toggle="tooltip"
                                title="Remove avatar"
                              >
                                <i className="bi bi-x fs-2"></i>
                              </span>
                            </div>
                            <div className="form-text">
                              Allowed file types: png, jpg, jpeg.
                            </div>
                          </div>
                        </div>

                        <div className="row mb-8">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Product Name
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="productName"
                                  onChange={handleChange}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="Product Name"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6  mb-8 ">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Price Per (ETB) (Kg)
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="number"
                                  min={0}
                                  name="pricePerKg"
                                  step="0.01"
                                  onChange={handleChange}
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder=" Price Per (Kg)"
                                />
                              </div>
                            </div>
                          </div>


                          <div className="col-lg-6 mb-8">
                              <div className="row">
                                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Product Unit
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <select
                                    className="form-select  flex-grow-1"
                                    // data-control="select2"
                                    data-placeholder="Please Select Category"
                                    data-hide-search="true"
                                    name="unit"
                                    onChange={handleChange}
                                    required
                                  >
                                    <option value="" selected disabled>
                                      Please Select Unit
                                    </option>
                                    {unitData.map((option) => (
                                  <option
                                    key={option.name}
                                    value={option.name}
                                  >
                                    {option.name}
                                  </option>
                                ))}                                    {/* <option value="Kg">KG</option>
                                    <option value="Ltr">Liter</option>
                                    <option value="GM">Gram</option>
                                    <option value="ML">Mililiter</option>
                                    <option value="Sachet">Sachet</option>
                                    <option value="Pcs">Pcs</option>
                                    <option value="Rolls">Rolls</option>
                                    <option value="Syring">Syring</option>
                                    <option value="Packs">Packs</option> */}
  
                                  </select>
                                </div>
                              </div>
                            </div>


                            <div className="col-lg-6 mb-8">
                              <div className="row">
                                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Purchase Unit
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <select
                                    className="form-select  flex-grow-1"
                                    // data-control="select2"
                                    data-placeholder="Please Select Category"
                                    data-hide-search="true"
                                    name="purchaseUnit"
                                    onChange={handleChange}
                                    required
                                  >
                                    <option value="" selected disabled>
                                      Please Select Unit
                                    </option>
                                    {unitData.map((option) => (
                                  <option
                                    key={option.name}
                                    value={option.name}
                                  >
                                    {option.name}
                                  </option>
                                ))} 
  
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6 mb-8">
                              <div className="row">
                                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Sales Unit
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <select
                                    className="form-select  flex-grow-1"
                                    // data-control="select2"
                                    data-placeholder="Please Select Category"
                                    data-hide-search="true"
                                    name="salesUnit"
                                    onChange={handleChange}
                                    required
                                  >
                                    <option value="" selected disabled>
                                      Please Select Unit
                                    </option>
                                    {unitData.map((option) => (
                                  <option
                                    key={option.name}
                                    value={option.name}
                                  >
                                    {option.name}
                                  </option>
                                ))} 
  
                                  </select>
                                </div>
                              </div>
                            </div>
  

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Unit
                              </label>
                              <div className="col-lg-8 fv-row">
                                <select
                                  className="form-select  flex-grow-1"
                                  // data-control="select2"
                                  data-placeholder="Please Select Category"
                                  data-hide-search="true"
                                  name="unit"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="" selected disabled>
                                    Please Select Unit
                                  </option>
                                  <option value="Kg">KG</option>
                                  <option value="Ltr">Liter</option>
                                  <option value="GM">Gram</option>
                                  <option value="ML">Mililiter</option>
                                  <option value="Sachet">Sachet</option>
                                  <option value="Pcs">Pcs</option>
                                  <option value="Rolls">Rolls</option>
                                  <option value="Syring">Syring</option>
                                  <option value="Packs">Packs</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Variety
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="variety"
                                  onChange={handleChange}
                                  min={0}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="Optional"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Description
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="description"
                                  onChange={handleChange}
                                  min={0}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  required
                                  placeholder="Enter Description"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-6">
                            <div className="row ">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Product Brand Name
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="test"
                                  name="companyName"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Enter Company Namewh"
                                  onChange={handleChange}
                                  required
                                  min={0}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row ">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Available Quantity
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="number"
                                  min={0}
                                  step="0.01"
                                  name="availableQuantity"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Available Quantity"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Category
                              </label>
                              <div className="col-lg-8 fv-row">
                                <select
                                  className="form-select  flex-grow-1"
                                  // data-control="select2"
                                  data-placeholder="Please Select Category"
                                  data-hide-search="true"
                                  name="category"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="" selected disabled>
                                    Please Select Category
                                  </option>
                                  <option value="Seeds">Seeds</option>
                                  <option value="Fertilizer">Fertilizer</option>
                                  <option value="chemicals">Chemicals</option>
                                  <option value="Veterinary Drugs">
                                    Veterinary Drugs
                                  </option>
                                  <option value="mechanazaionService">
                                    Mechanazaion service
                                  </option>
                                  <option value="technology">Technology</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-light btn-active-light-primary me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <OrgVerifyModel
        ShowModel={ShowPassword}
        setShowModel={setShowPassword}
        handleSubmit={AddFormData}
      />


    </>
  );
}

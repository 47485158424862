import React from "react";
import { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Swal from "sweetalert2";

export default function OrgVerifyModel({
  ShowModel,
  setShowModel,
  handleSubmit,
}) {
  let token = localStorage.getItem("organizationToken");

  const HanlerOrgVerify = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    try {
      const response = await axios.post(
        `/v1/ChromePay855658ADKI/kacha/verify-Password`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let data = response.data;
      if (data.status) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        handleSubmit();
        setShowModel(false);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        setShowModel(true);
        e.target.reset();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        setShowModel(true);
      }
    }
  };

  return (
    <div>
      <Modal
        className="modal-center"
        show={ShowModel}
        onHide={() => setShowModel(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={HanlerOrgVerify}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  {" "}
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              {" "}
              Verify
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
// import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LineChart, LabelList, CartesianGrid } from 'recharts';
import { DatePicker } from 'antd';
// import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";
// import UserGraph from "../Admin/Analytics/AdminUserGraph"
import UserTransGraph from "../Admin/Analytics/AdminTransComponent"
import AdminCopGraph from "../Admin/Analytics/AdminCopGraph"
import CustomerLoader from "../SuperAgent/CRM/Modal/CustomerLoder";
import ApiCountGraph from "../Admin/Analytics/ApiCountGraph";
import UserGraph from "./GraphComponent/UserJDCGraph"
import GraphComponent from "./common/GraphComponent";

var jwt = require("jsonwebtoken");


  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
// import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";


const JDCAnalyticPage = ({hadlerjdc}) =>{
    let token = localStorage.getItem("token");
    var decode1 = jwt.decode(token);
    //  let _id = decode1.OrganisationID
  
    const [Today_date, setToday_date] = useState([]);
    const [Last_Month, setLast_Month] = useState([]);
    const [useractivedata, setuseractivedata] = useState([]);
    const [useractivedatayear, setuseractivedatayear] = useState([]);
    const [useractivedatamonths, setuseractivedatamonths] = useState([]);
    const [useractiveSavingdatayear, setuseractiveSavingdatayear] = useState([]);
    const [useractiveSavingdatamonths, setuseractiveSavingdatamonths] = useState(
      []
    );
    const [ShowLoader, setShowLoader] = useState(true);
    const [useractiveStoredatayear, setuseractiveStoredatayear] = useState([]);
    const [useractiveStoredatamonths, setuseractiveStoredatamonths] = useState(
      []
    );
    const [section, setSection] = useState([]);
    const [dash, setDash] = useState([]);
    const [frequency, setFrequency] = useState("month");
    const [savingTypeFrequency, setsavingTypeFrequency] = useState("mandatory");
    const [savingFrequency, setSavingFrequency] = useState("month");
    const [storeFrequency, setStoreFrequency] = useState("month");
    const [data1, setData1] = useState([]);
    const [orgdash, setOrgDash] = useState([]);
    const [finduser, setFinduser] = useState([]);
    const [findTrans, setFindTrans] = useState([]);
    const [transData, setTransData] = useState([]);
    const [transmonths, setTransMonths] = useState([]);
    const [transvalue, setTransValue] = useState([]);
    const [analyticsData, setanalyticsData] = useState([]);
    const [allAnalyticalData, setAllAnalyticalData] = useState([]);
    const [graphData, setAnalyticGraphData] = useState([]);
    const [activeTab, setActiveTab] = useState("addSaving");
    const [chartData, setChartData] = useState({});
    const [filteredData, setFilteredData] = useState(graphData);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [latestTransValue, setLatestTransValue] = useState(0);
    const [lastMonthTrans, setLastMonthTransValue] = useState(0);
      const [dateRange, setDateRange] = useState(null);
    const handleDateFilterChange = (dates) => {
      setDateRange(dates);
    };
  
  
    console.log({ transvalue })
  
  
    useEffect(() => {
  
      setShowLoader(true);
  
      // Create the fetch function
      const fetchData = async () => {
        try {
          const response = await axios.post(
            '/v1/super-Agent/jdc-new-transaction-graph',
            {Type
              : "month",
              category
              : 
              "all",
              format
              : 
              "noncumulative"}, // You can pass the request body here if needed
            {
              headers: { Authorization: `Bearer ${token}` }
            }
          );
          const userData = response.data;
          console.log("Graph data:", userData);
  
      
          const totalAmountsByMonth = {};
            
          // Function to add totalAmount for each month
          const addTransactionTotals = (transactions) => {
            transactions.forEach(({ month, value, totalAmount }) => {
              if (!totalAmountsByMonth[month]) {
                totalAmountsByMonth[month] = {
                  monthName: value,
                  totalAmount: 0
                };
              }
              totalAmountsByMonth[month].totalAmount += totalAmount;
            });
          };
        
          // Process each category of transactions
          addTransactionTotals(userData?.loanTransactions || []);
          addTransactionTotals(userData?.savingTransactions || []);
          addTransactionTotals(userData?.shareTransactions || []);
          addTransactionTotals(userData?.storeTransactions || []);
        
          // Filter out months with a totalAmount of 0 and prepare data for display
          const filteredMonths = Object.keys(totalAmountsByMonth)
            .filter((month) => totalAmountsByMonth[month].totalAmount > 0) // Exclude months with 0 totalAmount
            .sort((a, b) => a - b) // Sort by month number
            .map((month) => totalAmountsByMonth[month]);
        
          const totalAmountsArray = filteredMonths.map((item) => item.totalAmount);
          const months = filteredMonths.map((item) => item.monthName);
        
          console.log("Filtered and sorted data:--->>>", { months, totalAmountsArray, totalAmountsByMonth });
        
          // Set state with filtered and sorted data
          setTransValue(totalAmountsArray);
          setTransMonths(months);
  
          // Set the latest transaction value (the last item in the array)
          const lastIndex = totalAmountsArray[totalAmountsArray.length - 1];
          const lastMonthIndex = totalAmountsArray[totalAmountsArray.length - 2];
          console.log("Latest value:", lastIndex);
          setLatestTransValue(lastIndex);
          setLastMonthTransValue(lastMonthIndex);
  
          // Hide the loader once data is fetched
          setShowLoader(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setShowLoader(false);
        }
      };
  
      fetchData();
    }, [token]);
  
  
  
  
    useEffect(() => {
      if (dateRange) {
  
        const filtered = graphData.filter((item) => {
          const month = item.month.toLowerCase();
          const startMonth = dateRange[0]?.toDate()?.toLocaleString('default', { month: 'long' }).toLowerCase();
          const endMonth = dateRange[1]?.toDate()?.toLocaleString('default', { month: 'long' }).toLowerCase();
          return month >= startMonth && month <= endMonth;
        });
        setFilteredData(filtered);
      } else {
        setFilteredData(graphData);
      }
    }, [dateRange, graphData]);
  
    const handleFromDateChange = (e) => setFromDate(e.target.value);
    const handleToDateChange = (e) => setToDate(e.target.value);
    const handleResetDID = () => {
      setToDate("");
      setFromDate("")
    };
    const formatNumber = (number) => {
      if (number >= 1000000) {
        return (number / 1000000).toFixed(2) + "M";
      } else if (number >= 1000) {
        return (number / 1000).toFixed(2) + "k";
      } else {
        return number?.toString();
      }
    };
  
  
    useEffect(() => {
      setShowLoader(true)
      // Fetch the data from your API
      const fetchData = async () => {
        try {
          const response = await axios.post(
            "/v1/super-Agent/jdc-total-transactions",
            { fromDate, toDate },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setTransData(response.data);
          setChartData(response.data.chartData);
          console.log("circle data ", response.data);
          setShowLoader(false)
  
        } catch (error) {
          console.error("Error fetching data", error);
          setShowLoader(false)
  
        }
      };
      fetchData();
    }, [toDate]);
  
    const doughnutData = {
      labels: Object.keys(chartData),
      datasets: [
        {
          label: "API Counts",
          data: Object.values(chartData),
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
          ],
        },
      ],
    };
  
  
    // Api count graph 
  
  
    const [dateRangeCout, setdateRangeCout] = useState({
      fromDate: "",
      toDate: ""
    });
  
  
    const handleDateChange = (e) => {
      const { name, value } = e.target;
      setdateRangeCout((prevState) => ({
        ...prevState,
        [name]: value
      }));
    };
  
  
    const handleResetCount = () => {
      setdateRangeCout({
        fromDate: "",
        toDate: ""
      });
    };
  
  
  
    function generateColorPalette(n) {
      const palette = [];
      for (let i = 0; i < n; i++) {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        palette.push(`#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`);
      }
      return palette;
    }
  
    const ApiCountData = {
      labels: analyticsData?.map((item, index) => (item?.type)),
      datasets: [
        {
          label: "API Counts",
          data: analyticsData?.map((item, index) => (item?.total)),
          backgroundColor: generateColorPalette(analyticsData.length),
          hoverBackgroundColor: generateColorPalette(analyticsData.length),
        },
      ],
    };
  
  
  
    const getRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };
  
    // Function to generate an array of random colors based on the number of data points
    const generateColors = (numColors) => {
      const colors = [];
      for (let i = 0; i < numColors; i++) {
        colors.push(getRandomColor());
      }
      return colors;
    };
  
    const backgroundColor = generateColors(ApiCountData.datasets[0].data.length);
    const hoverBackgroundColor = generateColors(ApiCountData.datasets[0].data.length);
  
  
    console.log("ApiCountData--->>", ApiCountData)
  
    const data = {
      labels: ApiCountData.labels,
      datasets: [
        {
          ...ApiCountData.datasets[0],
          backgroundColor: backgroundColor,
          hoverBackgroundColor: hoverBackgroundColor,
        },
      ],
    };
  
    const options = {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          beginAtZero: true,
        },
      },
    };
  
    const chartStyles = {
      width: '80%',
      height: '500px',
      margin: '0 auto',
    };
  
  
  
  
  
    //  const orgID = _id;
    const fetchCountData = async () => {
      setShowLoader(true)
      try {
        const response = await axios.post(
          "/v1/super-Agent/get-analytics",
          { fromDate: dateRangeCout?.fromDate, toDate: dateRangeCout?.toDate },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
  
        console.log("Api count data ", response.data.data);
        setShowLoader(false)
        setanalyticsData(response?.data?.latest_counts);
        setAnalyticGraphData(response?.data?.graphData);
        setAllAnalyticalData(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      fetchCountData()
    }, [dateRangeCout?.toDate])
  
  
  
  
  
  
    const userlist = () => {
      axios
        .post(
          `/v1/super-Agent/get-jdc-dash`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((resp) => {
          let data = resp.data;
          const findLicenseFees = data.findLicenseFees;
          const finduser = data.finduser;
          const findTrans = data.findTrans;
          setData1(data);
          setOrgDash(findLicenseFees);
          setFinduser(finduser);
          setFindTrans(findTrans);
          console.log("@@@", finduser);
          //console.log("@@@",findLicenseFees);
          setShowLoader(false);
        });
    };
  
    const UserPermoance = async () => {
      await axios
        .post(
          `/v1/super-Agent/superagent-report`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          const userData3 = res.data;
          const userData = res.data.detail;
          setuseractivedata(userData3);
          setDash(userData);
          console.log("++++++++++++++12", userData);
          setShowLoader(false);
        });
    };
  
    const orgTransectionsSection = async () => {
      await axios
        .post(
          `/v1/org/reports/org-detail`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((resp) => {
          const data = resp.data.data;
          // console.log("123", data);
          setSection(data);
        });
    };
  
    const getIcon = (key) => {
      switch (key) {
        case "Add Saving":
          return "fas fa-piggy-bank";
        case "Create DID":
          return "fas fa-id-card";
        case "Update DID":
          return "fas fa-edit";
        case "Add Loan":
          return "fas fa-hand-holding-usd";
        case "Pay EMI":
          return "fas fa-money-check-alt";
        case "Withdrawal Saving":
          return "fas fa-piggy-bank";
        case "Add Share":
          return "fas fa-chart-line";
        case "Withdrawal Share":
          return "fas fa-chart-line-down";
        case "Buy Product":
          return "fas fa-shopping-cart";
        case "Voluntary To Mandatoary":
          return "fas fa-exchange-alt";
        default:
          return "fas fa-chart-bar";
      }
    };
  
    useEffect(() => {
      UserPermoance();
      // UserPermoanceyear();
      orgTransectionsSection();
      userlist();
      fetchCountData();
      // setTimeout(() => {
  
      // }, 1000);
  
      hadlerjdc();
    }, []);
    // console.log("123");
    // console.log("test", section);
    const transactionGraph = async (sort) => {
      const body = { Type: frequency };
      await axios
        .post(`/v1/super-Agent/get-did-graph`, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const userData = res.data.data;
  
          //    console.log("++++++++++++++123", userData);
          const categories = userData.map((item) => item.count);
          const users = userData.map((item) => item.value);
  
          setuseractivedatayear(categories);
          setuseractivedatamonths(users);
          // console.log("$$$", categories)
          // setTranscation(data);
          // setuseractivedatayear(Object.values(userData));
  
          // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj", useractivedatayear);
        });
    };
  
    const savingTransactionGraph = async (sort) => {
      const body = { Type: savingFrequency, savingType: savingTypeFrequency };
      await axios
        .post(`/v1/super-Agent/get-savingtrans-graph`, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const userData = res.data.data;
  
          //    console.log("++++++++++++++123", userData);
          const categories = userData.map((item) => item.totalSendingAmount);
          const users = userData.map((item) => item.value);
  
          setuseractiveSavingdatayear(categories);
          setuseractiveSavingdatamonths(users);
          // console.log("$$$", categories)
          // setTranscation(data);
          // setuseractivedatayear(Object.values(userData));
  
          // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj", useractivedatayear);
        });
    };
  
    const storeTransactionGraph = async (sort) => {
      const body = { Type: storeFrequency };
      await axios
        .post(`/v1/super-Agent/get-storetrans-graph`, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const userData = res.data.data;
  
          //    console.log("++++++++++++++123", userData);
          const categories = userData.map((item) => item.totalSendingAmount);
          const users = userData.map((item) => item.value);
  
          setuseractiveStoredatayear(categories);
          setuseractiveStoredatamonths(users);
          // console.log("$$$", categories)
          // setTranscation(data);
          // setuseractivedatayear(Object.values(userData));
  
          // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj", useractivedatayear);
        });
    };
  
    // const datafilter = (e) => {
  
    //     e.preventDefault();
    //     const value = e.target.value;
  
    //     axios.post(`/v1/org/reports/get_org_cust_data_graph`, { filter: value }, { headers: { "Authorization": `bearer ${token}` } }).then((res) => {
    //         var userData = res.data.obj;
  
    //         console.log("++++++++++++++graph data", userData);
  
    //         setuseractivedatayear(Object.values(userData));
    //         setuseractivedatamonths(Object.keys(userData));
    //     });
    // };
  
    // console.log('====================================');
    // console.log(section);
    // console.log('====================================');
  
    useEffect(() => {
      transactionGraph();
    }, [frequency]);
  
    useEffect(() => {
      savingTransactionGraph();
    }, [savingFrequency]);
  
    useEffect(() => {
      savingTransactionGraph();
    }, [savingTypeFrequency]);
  
    useEffect(() => {
      storeTransactionGraph();
    }, [storeFrequency]);
  
    const handlesorting = (event) => {
      let sort = event.target.value;
      setFrequency(sort);
      transactionGraph(sort);
      console.log(sort);
    };
    const handleSavingsorting = (event) => {
      let sort = event.target.value;
      setSavingFrequency(sort);
      savingTransactionGraph(sort);
      console.log(sort);
    };
    const handleSavingTypesorting = (event) => {
      let sort = event.target.value;
      setsavingTypeFrequency(sort);
      savingTransactionGraph(sort);
      console.log(sort);
    };
    const handleStoreTypesorting = (event) => {
      let sort = event.target.value;
      setStoreFrequency(sort);
      storeTransactionGraph(sort);
      console.log(sort);
    };
    return (
      <>
        <CustomerLoader loader={ShowLoader} />
  
        <div
          className="wrapper d-flex flex-column flex-row-fluid mb-5 back-color"
          id="kt_wrapper"
        >
          <div
            className="content d-flex flex-column flex-column-fluid mb-0 py-0"
            id="kt_content"
          >
            <div className="container-xxl mb-0" id="kt_content_container">
              <div className="card-body pt-9 pb-0">
                <h2
                  className="text-center mb-4"
                  style={{ color: "#4A90E2", fontWeight: "bold" }}
                >
                  Performance Overview
                </h2>
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="card rounded-15 mb-5 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                      style={{ paddingBottom: "1rem" }}
                    >
                      <div className="card-body pt-9 pb-5">
                        <div className="row gy-2">
  
  
                          {/* Total Transaction */}
  
                          <div className="col-md-6 col-lg-3">
                            <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i className="fad fa-users fs-2"></i>
                                  </span>
                                </span>
                              </div>
                              <div className="">
                                <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                  {formatNumber(transData?.totalTransactions || "Loading...")}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Transactions
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
  
  
                          {/* Box 1: New Users Registered */}
                          <div className="col-md-6 col-lg-3">
                            <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i className="fad fa-users fs-2"></i>
                                  </span>
                                </span>
                              </div>
                              <div className="">
                                <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                  {formatNumber(transData?.totalDID || "Loading...")}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Customers
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
  
                          {/* Box 2: Onboarding Target */}
                          <div className="col-md-6 col-lg-3">
                            <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i className="fas fa-bullseye-arrow fs-2"></i>
                                  </span>
                                </span>
                              </div>
                              <div className="">
                                <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                  {formatNumber(transData?.LoanAmount || 0)}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Loan Amount (ETB)
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
  
                          {/* Box 3: Total Store Sales */}
                          <div className="col-md-6 col-lg-3">
                            <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i className="fas fa-dollar-sign fs-3"></i>
                                  </span>
                                </span>
                              </div>
                              <div className="">
                                <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                  {formatNumber(transData?.Store)}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Store Sales
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
  
                          {/* Box 4: Total Saving Amount */}
                          <div className="col-md-6 col-lg-3">
                            <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i className="fas fa-piggy-bank fs-4"></i>
                                  </span>
                                </span>
                              </div>
                              <div className="">
                                <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                  {formatNumber(transData?.Saving)}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Saving Amount (ETB)
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
  
                          {/* Box 5: Total Customers */}
                          <div className="col-md-6 col-lg-3">
                            <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i class="fas fa-piggy-bank fs-2"></i>
                                  </span>
                                </span>
                              </div>
                              <div className="">
                                <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                  {formatNumber(transData?.totalSavingsWithd)}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Savings Withdrawal
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
  
                          {/* Box 6: Total paid loan */}
  
                          <div className="col-md-6 col-lg-3">
                            <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i class="fas fa-landmark fs-2"></i>
                                  </span>
                                </span>
                              </div>
                              <div className="">
                                <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                  {formatNumber(transData?.totalPaidLoan)}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Paid Loan Amount (ETB)
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
  
                          {/* Box 7: Total paid loan */}
  
                          <div className="col-md-6 col-lg-3">
                            <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i class="fas fa-landmark fs-2"></i>
                                  </span>
                                </span>
                              </div>
                              <div className="">
                                <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                  {formatNumber(transData?.TotalUnions)}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Unions
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                          {/* Box 8: Total paid loan */}
  
                          <div className="col-md-6 col-lg-3">
                            <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i class="fas fa-landmark fs-2"></i>
                                  </span>
                                </span>
                              </div>
                              <div className="">
                                <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                  {formatNumber(transData?.TotalMicrofinance)}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Microfinances
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                          {/* Box 9: Total paid loan */}
  
                          <div className="col-md-6 col-lg-3">
                            <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i class="fas fa-landmark fs-2"></i>
                                  </span>
                                </span>
                              </div>
                              <div className="">
                                <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                  {formatNumber(transData?.TotalBank)}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Banks
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                          {/* Box 10: Total paid loan */}
  
                          <div className="col-md-6 col-lg-3">
                            <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i class="fas fa-landmark fs-2"></i>
                                  </span>
                                </span>
                              </div>
                              <div className="">
                                <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                  {formatNumber(transData?.TotalTotalCooperative)}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Cooperatives
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-xxl mb-0" id="kt_content_container">
              <div className="card-body pt-9 pb-0">
                <div className="row mt-5">
                  <div className="col-lg-6">
                    <div className="card rounded-15 mb-5 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                      <div className="card-body">
                        <h3
                          className="text-center mb-4"
                          style={{ color: "#4A90E2", fontWeight: "bold" }}
                        >
                          Transaction Distribution
                        </h3>
  
                        <div className="rounded d-flex flex-column flex-lg-row mend align-items-lg-center bg-body p-5 h-lg-60px me-lg-10 my-5">
                          <div className="row      flex-grow-1 mb-5 mb-lg-0">
                            <div className="col-md-4 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                              <label className="small_label my-label">Start Date</label>
                              <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                                <i className="fad fa-calendar"></i>
                              </span>
                              <input
                                type="date"
                                className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                                value={fromDate}
                                onChange={handleFromDateChange}
                              />
                              <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                            </div>
  
                            <div className="col-md-4 d-flex ps-1 align-items-center mb-3 input-container mb-lg-0">
                              <label className="small_label my-label">End Date</label>
                              <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                                <i className="fad fa-calendar"></i>
                              </span>
                              <input
                                type="date"
                                className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                                value={toDate}
                                onChange={handleToDateChange}
                              />
                            </div>
  
                            <div className="col-md-3 d-flex align-items-center mb-3 justify-content-start ps-1 input-container mb-lg-0">
                              <button
                                type="reset"
                                onClick={() => handleResetDID()}
                                className="btn btn-light btn-active-light-primary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
  
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-center"
                          style={{ height: "67vh", overflow: "scroll" }}
                        >
                          <div style={{ width: "60%" }}>
                            <Doughnut data={doughnutData} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div className="col-lg-6">
                    <div
                      className="card rounded-15 mb-5 mb-xl-10  bgi-no-repeat bgi-position-x-end bgi-size-cover"
                      style={{ paddingBottom: "1rem" }}
                    >
                      <div className="card-body  pt-9 pb-5 ">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="retative bg-gray-100 animestion-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5 ">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i className="fad fa-users"></i>
                                  </span>
                                </span>
                              </div>
  
                              {Last_Month <= 0 ? (
                                <i className="fal fa-arrow-up fz-20"></i>
                              ) : (
                                <i className="fal fa-arrow-down fz-21"></i>
                              )}
                              <div className=" ">
                                {/* {Last_Month <= 0 ? ( */}
                                <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1  ">
                                  {formatNumber(
                                   latestTransValue || 0
                                  )}
                                </span>
  
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6  ">
                                    New Transactions
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
  
                          <div className="col-md-6">
                            <div className="retative bg-gray-100 animestion-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5 ">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i className="fad fa-users"></i>
                                  </span>
                                </span>
                              </div>
  
                              <i class="fas fa-bullseye-arrow fz-200"></i>
  
                              <div className=" ">
                                <span className="text-gray-700 fz-230 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1  ">
                                  {formatNumber(
                                    lastMonthTrans || 0
                                  )}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6  ">
                                    Last Month Transactions
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
  
                          <div className="col-md-6" style={{ marginTop: "40px" }}>
                            <div className="retative bg-gray-100 animestion-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5 ">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i className="fad fa-users"></i>
                                  </span>
                                </span>
                              </div>
  
                              {Last_Month <= 0 ? (
                                <i className="fal fa-arrow-up fz-20"></i>
                              ) : (
                                <i className="fal fa-arrow-down fz-21"></i>
                              )}
                              <div className=" ">
                                {/* {Last_Month <= 0 ? ( */}
                                <span
                                  // data-kt-countup="true"
                                  // data-kt-countup-value={
                                  //    transData?.lastMonthTotalTransactions || 0
                                  // }
                                  className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1  "
                                >
                                  {formatNumber(
                                    transData?.lastMonthCustomerCount || 0
                                  )}
                                </span>
  
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6  ">
                                    New DID's Register
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
  
                          {/* <div className="col-md-6" style={{ marginTop: "40px" }}>
                            <div className="retative bg-gray-100 animestion-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5 ">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i className="fad fa-users"></i>
                                  </span>
                                </span>
                              </div>
  
                              <i class="fas fa-bullseye-arrow fz-200"></i>
  
                              <div className=" ">
                                <span className="text-gray-700 fz-230 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1  ">
                                  {formatNumber(transData.onBoarding)}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6  ">
                                    Onboarding target for Next month
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="container-xxl" id="kt_content_container">
              <div className="row g-5 g-xl-8">
                <div className="col-lg-12">
                  <div className="row">
  
  
                  
                    <UserGraph />
                    <GraphComponent />
                    {/* <AdminCopGraph /> */}
                  
                  </div>
                </div>
              </div>
            </div>
  
            {/* <div className="container-xxl mb-0" id="kt_content_container">
              <div className="card-body pt-9 pb-0">
                <h2
                  className="text-center mb-4"
                  style={{ color: "#4A90E2", fontWeight: "bold" }}
                >
                  API Count
                </h2>
                <div className="row">
                  <div className="col-lg-12">
  
  
                  <div
                      className="card rounded-15 mb-5 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                      style={{
                        paddingBottom: "1rem",
                        border: "1px solid #E1E4E8",
                      }}
                    >
                      <div className="card-body pt-9 pb-5">
                        <div className="row">
                          {analyticsData?.map((item, index) => (
                            <div className="col-md-6 col-lg-3 mb-4" key={index}>
                              <div className="card h-100 bg-light rounded-2 px-6 py-5 shadow-sm hover-shadow-lg transition-all">
                                <h6 className="text-muted fw-semibold fs-6">
                                  {item?.type}
                                </h6>
  
                                <div className="d-flex align-items-center mb-3">
                                  <div className="symbol symbol-40px me-4">
                                    <span className="symbol-label">
                                      <i
                                        className={`${getIcon(
                                          item?.type
                                        )} text-primary fs-2`}
                                      ></i>
                                    </span>
                                  </div>
                                  <div>
                                    <span className="text-primary fz-23 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                      {item?.total}
                                    </span>
                                  </div>
                                </div>
  
                                <div className="d-flex align-items-center mb-3 justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-40px me-4">
                                      <span className="symbol-label">
                                        <i className="fas fa-check-circle text-primary fs-2" />
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-primary fz-18 fw-bolder d-block fs-4 lh-1 ls-n1 mb-1">
                                        {item?.success}
                                      </span>
                                    </div>
                                  </div>
  
                                  <div className=" d-flex align-items-center">
                                    <div className="symbol symbol-40px me-4">
                                      <span className="symbol-label">
                                        <i class="fas fa-times-circle text-danger fs-2"></i>
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-danger fz-18 fw-bolder d-block fs-4 lh-1 ls-n1 mb-1">
                                        {item?.total - item?.success}
                                      </span>
                                    </div>
                                  </div>
                                </div>
  
                                <div
                                  className="progress"
                                  style={{ height: "5px" }}
                                >
                                  <div
                                    className="progress-bar bg-primary"
                                    role="progressbar"
                                    style={{
                                      width: `${(item?.success / 100) * 100}%`,
                                    }}
                                    aria-valuenow={item?.success}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
  
  
  
                    <div className="card rounded-15 mb-5 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                      <div className="card-body">
  
  
                        <div className="rounded align-items-lg-center p-5 me-lg-10 my-5">
                          <div className="row align-items-center justify-content-end flex-grow-1 mb-5 mb-lg-0">
                            <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                              <label className="small_label my-label">Start Date</label>
                              <span className="svg-icon svg-icon-1 svg-icon-gray-400">
                                <i className="fad fa-calendar"></i>
                              </span>
                              <input
                                type="date"
                                className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                                name="fromDate" // Ensure the name matches the state key
                                value={dateRangeCout.fromDate}
                                onChange={handleDateChange}
                              />
                              <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                            </div>
  
                            <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                              <label className="small_label my-label">End Date</label>
                              <span className="svg-icon svg-icon-1 svg-icon-gray-400">
                                <i className="fad fa-calendar"></i>
                              </span>
                              <input
                                type="date"
                                className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                                name="toDate" // Ensure the name matches the state key
                                value={dateRangeCout.toDate}
                                onChange={handleDateChange}
                              />
                              <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                            </div>
  
  
  
                            <div className="col-md-1 d-flex align-items-center mb-3 justify-content-start ps-1 input-container mb-lg-0">
                              <button
                                type="reset"
                                onClick={handleResetCount}
                                className="btn btn-light btn-active-light-primary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
  
  
  
                        <div
                          className="d-flex justify-content-center"
                          style={{ height: "100vh", overflow: "scroll" }}
                        >
                          <div style={{ width: "50%" }}>
                            <Doughnut data={ApiCountData} />
                          </div>
                        </div>
                      </div>
                    </div>
  
  
  
  
                  
  
  
  
  
  
  
  
                  </div>
                </div>
              </div>
            </div> */}
  
  
            {/* Horizontal view */}
            {/* <div className="rounded align-items-lg-center p-5 me-lg-10 my-5">
  
              <div className="row   align-items-center justify-content-center  mb-5 ">
                <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                  <label className="small_label my-label">Start Date</label>
                  <span className="svg-icon svg-icon-1 svg-icon-gray-400">
                    <i className="fad fa-calendar"></i>
                  </span>
                  <input
                    type="date"
                    className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                    name="fromDate" // Ensure the name matches the state key
                    value={dateRangeCout.fromDate}
                    onChange={handleDateChange}
                  />
                  <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                </div>
  
                <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                  <label className="small_label my-label">End Date</label>
                  <span className="svg-icon svg-icon-1 svg-icon-gray-400">
                    <i className="fad fa-calendar"></i>
                  </span>
                  <input
                    type="date"
                    className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                    name="toDate" // Ensure the name matches the state key
                    value={dateRangeCout.toDate}
                    onChange={handleDateChange}
                  />
                  <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                </div>
  
  
  
                <div className="col-md-1 d-flex align-items-center mb-3 justify-content-start ps-1 input-container mb-lg-0">
                  <button
                    type="reset"
                    onClick={handleResetCount}
                    className="btn btn-light btn-active-light-primary"
                    id="kt_advanced_search_button_1"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div> */}
  
            {/* <div className="d-flex justify-content-center" style={{ height: "100vh", overflow: "scroll" }}>
              <div style={chartStyles}>
                <Bar data={data} options={options} />
              </div>
            </div> */}
  
  
  
            {/* test chart */}
  
            {/* <div className="container">
        <h2 className="text-center mb-4" style={{ color: "#4A90E2", fontWeight: "bold" }}>
          API Analytics
        </h2>
  
        <div className="text-center mb-4">
          <DatePicker.RangePicker onChange={handleDateFilterChange} format="MMMM YYYY" picker="month" />
        </div>e
  
        <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-center mb-3" style={{ color: "#3c7f8c", fontWeight: "bold" }}>
            Latest Counts
          </h4>
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              data={analyticsData}
              margin={{ top: 20, right: 40, left: 20, bottom: 10 }}
              barCategoryGap="20%"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="type" tick={{ fontSize: 12, fontWeight: "bold" }} />
              <YAxis tick={{ fontSize: 12, fontWeight: "bold" }} />
              <Tooltip />
              <Legend verticalAlign="top" wrapperStyle={{ fontSize: "14px", fontWeight: "bold" }} />
              <Bar dataKey="total" fill="#3c7f8c" radius={[10, 10, 0, 0]}>
                <LabelList dataKey="total" position="top" style={{ fontSize: 14, fontWeight: "bold" }} />
              </Bar>
              <Bar dataKey="success" fill="#82ca9d" radius={[10, 10, 0, 0]}>
                <LabelList dataKey="success" position="top" style={{ fontSize: 14, fontWeight: "bold" }} />
              </Bar>
              <Bar
                dataKey="failures"
                fill="#ff7373"
                radius={[10, 10, 0, 0]}
                name="Failures"
              >
                <LabelList dataKey="failures" position="top" style={{ fontSize: 14, fontWeight: "bold" }} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
  
        <div className="row">
          <div className="col-12">
            <h4 className="text-center mb-3" style={{ color: "#4A90E2", fontWeight: "bold" }}>Monthly Data</h4>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={filteredData}>
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" name="createDID" stroke="#8884d8" />
                <Line type="monotone" dataKey="addshare" name="Add Share" stroke="#82ca9d" />
                <Line type="monotone" dataKey="withdrawalSaving" name="Withdrawal Saving" stroke="#ff7373" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div> */}
  
  
  
  
  
  
  
  
  
  
  
  
  
            {/* api call */}
  
          </div>
  
  
  
          {/* saving managment */}
  
          {/* <UserGraph/> */}
  
          {/* <div className="container-xxl" id="kt_content_container">
              <div className="row g-5 g-xl-8">
                <div className="col-lg-12">
                  <div className="row">
  
  
                  
                  <ApiCountGraph />
                  
                  </div>
                </div>
              </div>
            </div> */}
  
  
       
  
        </div>
      </>
    );
  };
  

export default JDCAnalyticPage

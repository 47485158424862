import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ReactPaginate from "react-paginate";

import { useState, useRef, useEffect } from "react";
import { JWT } from "jsonwebtoken";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { error } from "jquery";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import moment from "moment";
import { Pagination } from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import LoanAccountManagement from "./LoanAccountManagement/LoanAccountManagement";
import SavingAccountManagement from "./SavingAccountManagement/SavingAccountManagement";
import ShareAccountManagement from "./ShareAccountManagement/ShareAccountManagement";
import StoreAccountManagement from "./StoreAccountManagement/StoreAccountManagement";
import AllAcoundManagement from "./AllAcoundManagement/AllAcoundManagement";

var jwt = require("jsonwebtoken");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function AccountManagementSuperAgent({ hadlersuper }) {
  const [activeTab, setactiveTab] = useState("loanManagement");

  let token = localStorage.getItem("SuperAgentChromepayToken");
  let { _id } = useParams();

  useEffect(() => {
    hadlersuper();
  }, []);

  return (
    <>
      <ToastContainer position="top-right" />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
          bgi-size-cover bg_card"
                    >
                      <div className="card-body flex-column p-5">
                        <div className="card-rounded  col-12 d-flex flex-stack flex-wrap p-5 pb-0">
                          <ul
                            className="nav flex-wrap border-transparent fw-bold"
                            role="tablist"
                          >
                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#loanManagement"
                                className="btn btn-color-gray-600 btn-active-secondary  active btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Loan Management
                              </a>
                            </li>

                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#SavingManagement"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Saving Management
                              </a>
                            </li>
                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#ShareManagement"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Share Management
                              </a>
                            </li>
                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#StoreManagement"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Store Management
                              </a>
                            </li>
                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#AllAccountManagement"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                All Account Management
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content p-2">
                    <div
                      className="tab-pane fade active show"
                      id="loanManagement"
                      role="tabpanel"
                    >
                   
                        <LoanAccountManagement />
                  
                    </div>

                    <div
                      className="tab-pane fade p-2"
                      id="SavingManagement"
                      role="tabpanel"
                    >
                        <SavingAccountManagement />
                    </div>

                    <div
                      className="tab-pane fade"
                      id="ShareManagement"
                      role="tabpanel"
                    >
                      <div className="row">
                        <ShareAccountManagement />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="StoreManagement"
                      role="tabpanel"
                    >
                      <div className="row">
                        <StoreAccountManagement />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="AllAccountManagement"
                      role="tabpanel"
                    >
                      <div className="row">
                    <AllAcoundManagement/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

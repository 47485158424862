import { useState, useRef, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import axios from "axios";
import moment from "moment";
export default function SuperAgentSavingIntrestCompontent({ _id }) {
  let token = localStorage.getItem("organizationToken");

  const [interestData, setInterstSaving] = useState([]);

  const interestDetails = () => {
    axios
      .post(
        `/v1/super-Agent/get-saving-interest-transactions`,
        {
          custId: _id,
          page: 1,
          type: "all",
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setInterstSaving(saveData);
      });
  };

  useEffect(() => {
    interestDetails();
  }, []);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                Recent Transactions
              </span>
              <span className="text-muted mt-1 fw-semibold fs-7"></span>
            </h3>
            <h3 className="card-title align-items-start gap-5">
              <div className="row">
                {/* <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                  <div className="d-flex">
              
                    <label className="form-check form-check-inline fs-6">
                      <input
                        type="radio"
                        name="Type"
                        value="mandatory"
                        checked={
                          intrestfilterawdata == "mandatory"
                        }
                        onChange={(e) =>
                          setintrestfilterawdata(
                            e.target.value
                          )
                        }
                        className="form-check-input"
                      />
                      Mandatory
                    </label>
                    <label className="form-check form-check-inline fs-6">
                      <input
                        type="radio"
                        name="Type"
                        value="voluntary"
                        onChange={(e) =>
                          setintrestfilterawdata(
                            e.target.value
                          )
                        }
                        className="form-check-input"
                      />
                      Voluntary
                    </label>
                  </div>
                </div> */}

                <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                  <div className="d-flex">
                    {/* <select
                      name="marriageStatus"
                      className="form-select  flex-grow-1"
                      data-placeholder="Please Select"
                      onChange={(e) =>
                        setintrestfilterawdata(e.target.value)
                      }
                    >
                      <option value={"all"}>All</option>
                      <option value={"voluntary"}>
                        Voluntary
                      </option>
                      <option value={"mandatory"}>
                        Mandatory
                      </option>
                    </select> */}
                  </div>
                </div>
              </div>
              <div className="text-end">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn  btn-sm btn-SUCCESS "
                  table="saving-table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText={
                    <>
                      <i className="fad fa-download fs-1"></i>
                    </>
                  }
                />
              </div>
            </h3>
          </div>

          <div className="card-body py-3">
            <div className="table-responsive">
              <table
                id="saving-table-to-xls"
                className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
              >
                <thead>
                  <tr className="fw-bold text-muted th-title">
                    <th className="min-w-125px">Month Date</th>
                    <th className="min-w-150px">Total Amount (ETB)</th>
                    <th className="min-w-150px">Interest Amount (ETB)</th>
                    {/* <th className="min-w-100px">
                      Intrest
                      <br /> Rate{" "}
                    </th> */}
                    <th className="min-w-100px">Total Balance (ETB)</th>
                    {/* <th className="min-w-100px">
                      Cancel
                    </th> */}
                    {/* <th className="min-w-100px">
                      Invoice
                    </th> */}
                  </tr>
                </thead>

                <tbody>
                  {interestData.map((item) => (
                    <tr key={item.id}>
                      <td>
                        {moment(item.date).format("MMMM Do YYYY, h:mm:ss a")}
                      </td>
                      <td>{item.totalAmount}</td>
                      <td>{item.totalInterest}</td>
                      <td>{item.balance}</td>

                      {/* <td className="text-center">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => handleDownloadInvoice(item.invoice)}
                        >
                          Download Invoice
                        </button>
                      </td> */}
                      {/* <td>
                        {item.remainingAmount.toFixed(2)}
                      </td> */}

                      {/* <td>
                       <div className="d-flex justify-content-center flex-shrink-0">
                          <a
                            href="digital_user_detail.html"
                            className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-trigger="hover"
                            data-kt-initialized="1"
                          >
                            <span className="svg-icon svg-icon-3">
                              <i className="fad fa-eye fs-4"></i>
                            </span>
                          </a>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <div className="col-lg-12 mt-2 text-end">
              <ReactPaginate
                PreviousLabel={"Previous"}
                NextLabel={"Next"}
                breakLabel={"..."}
                pageCount={SIpage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={SavingPageClick}
                containerClassName={
                  "pagination justify-content-end gap-sm-3"
                }
                pageClassName={"page-item mx-2"}
                pageLinkClassName={"page-link"}
                PreviousClassName={"page-item"}
                PreviousLinkClassName={"page-link pagestyle"}
                NextClassName={"page-item"}
                NextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import CapitalLoan from "./Components/CapitalLoan";
import DistributionLoan from "./Components/DistributionLoan";

var jwt = require("jsonwebtoken");

export default function LoanAccountManagement() {
 



  return (
    <>
      <div className="row g-5 g-xl-8">
        <div className="col-lg-12">
          <div
            className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
          bgi-size-cover bg_card"
          >
            <div className="card-body flex-column p-5">
              <div className="card-rounded  col-12 d-flex flex-stack flex-wrap p-5 pb-0">
                <ul
                  className="nav flex-wrap border-transparent fw-bold"
                  role="tablist"
                >
                  <li className="nav-item my-1" role="presentation">
                    <a
                      role="tab"
                      aria-selected="true"
                      data-bs-toggle="tab"
                      href="#Capitalloan"
                      className="btn btn-color-gray-600 btn-active-secondary  active btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                    >
                      Capital Management
                    </a>
                  </li>

                  <li className="nav-item my-1" role="presentation">
                    <a
                      role="tab"
                      aria-selected="true"
                      data-bs-toggle="tab"
                      href="#distribution"
                      className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                    >
                      Distribution Management
                    </a>
                  </li>
            
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-content p-2 m-0">
          <div
            className="tab-pane fade active show"
            id="Capitalloan"
            role="tabpanel"
          >
            <CapitalLoan />
          </div>

   

      
          <div
            className="tab-pane fade p-2 m-0"
            id="distribution"
            role="tabpanel"
          >
              <DistributionLoan />
          </div>
        </div>
      </div>
    </>
  );
}

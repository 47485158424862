import React from "react";
import { useState, useRef, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import axios from "axios";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrgVerifyModel from "../../../OrgShareManagement/Componets/OrgVerifyModel";
import CustomerLoader from "../../../../SuperAgent/CRM/Modal/CustomerLoder";

export default function VoluntaryToMandatoryTransferComponets({
  _id,
  userDetail,
  handleReloadData,
}) {
  let token = localStorage.getItem("organizationToken");
  const [VolandManDetails, setVolandManDetails] = useState([]);
  const [WithAmount, setWithAmount] = useState("");
  const [Page, setPage] = useState(0);
  const [ShowModel, setShowModel] = useState(false);
  const [ShowCancelModel, setShowCancelModel] = useState(false);
  const [ShowLoader, setShowLoader] = useState(false);
  const [transId, setTransId] = useState("");

  const limit = 10;

  const VoluntaryToMandatoryhistory = () => {
    axios
      .post(
        `/v1/super-Agent/trasfer-logs`,
        { custId: _id, page: 1 },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setVolandManDetails(saveData);

        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setPage(totalPage || 0);
      });
  };

  useEffect(() => {
    VoluntaryToMandatoryhistory();
  }, []);

  const FundTransferRequired = (e) => {
    e.preventDefault();
    setShowModel(true);
  };

  const FundTransferor = async (e) => {
    setShowLoader(true);
    let payload = {
      amount: WithAmount,
      agentId: _id,
    };

    if (parseInt(WithAmount) > parseInt(userDetail?.voluntary)) {
      Swal.fire({
        icon: "error",
        title: "Insufficient Funds",
        text: "You cannot withdraw this amount; your wallet does not have sufficient voluntary funds.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } else {
      axios
        .post(`/v1/org/CRM/vol-to-mand`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })

        .then((resp) => {
          let data = resp.data;

          if (data.status) {
            toast.success(data.msg);
            setShowLoader(false);
            handleReloadData();
            VoluntaryToMandatoryhistory();
          } else {
            toast.error(data.msg);
            setShowLoader(false);
          }
        });
    }
  };

  const Transitioncancel = (item) => {
    let _id = item?._id;
    console.log({ _id });
    if (item?.status == "cancel") {
      toast.error("This transaction has already been canceled.");
    } else {
      setTransId(_id);
      setShowCancelModel(true);
    }
  };

  const handleCancelTransaction = async () => {
    setShowLoader(true);
    try {
      const response = await axios.post(
        `/v1/org/CRM/cancel-super-saving-transaction`,
        { savingId: transId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.status) {
        setShowCancelModel(false);
        setShowLoader(false);
        toast.success(response.data.msg);

        handleReloadData();
        VoluntaryToMandatoryhistory();
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {}
  };

  const DownloadInvoice = (invoiceData) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();
    console.log({ invoiceData });

    // Set up styling for the PDF
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    const width = doc.internal.pageSize.getWidth();

    // Calculate the width of the text for the invoice heading
    const headingTextWidth =
      (doc.getStringUnitWidth("Your Saving Deposit Invoice") *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (width - headingTextWidth) / 2;

    // Add the invoice heading
    doc.text("Your Withdrawal Deposit Invoice", headingX, 20);

    // Format the date as dd mm yyyy
    const formattedDate = new Date(invoiceData.activityDate).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }
    );

    // Add the date on the right side
    const dateTextWidth =
      (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Date: ${formattedDate}`, dateX, 40);

    // Set up styling for the section headings
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);

    // Add the customer details heading with left alignment
    doc.text("Customer Details", 10, 60);

    // Add the customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Customer ID: ${userDetail.agentId}`, 10, 80);
    doc.text(`Customer Name: ${userDetail.firstName}`, 10, 90);
    doc.text(`Customer Phone: ${userDetail.phone}`, 10, 100);
    // Add the saving details heading with left alignment
    doc.text("Saving Details", 10, 120);
    // Add the share details table
    if (invoiceData.payType == "mandatory") {
      doc.autoTable({
        startY: 140,
        head: [
          [
            "Share ID",
            "Saving ID",
            "Share Price (ETB)",
            "Saving Interest %",
            "Saving Type",
          ],
        ],
        body: [
          [
            invoiceData.shareId,
            invoiceData._id,
            ` ${invoiceData.shareAm}`,
            `${invoiceData.interestRate || 0}`,
            invoiceData.savingType,
          ],
        ],
      });

      // Add the total share amount and total saving amount on the right side in a smaller font size
      const totalTextSize = 10;
      const totalX = width - 70; // Adjust as needed
      doc.setFontSize(totalTextSize);
      doc.text(
        `Total Share Amount (ETB):  ${invoiceData.shareAmount || 0}`,
        totalX,
        doc.autoTable.Previous.finalY + 10
      );
      doc.text(
        `Total Saving Amount (ETB):  ${invoiceData.totalAmount}`,
        totalX,
        doc.autoTable.Previous.finalY + 20
      );
    } else {
      doc.autoTable({
        startY: 140,
        head: [["Saving ID", "Saving Interest %", "Saving Type"]],
        body: [
          [invoiceData._id, `${invoiceData.interestRate || 0}`, "Voluntary"],
        ],
      });

      // Add the total saving amount on the right side in a smaller font size
      const totalTextSize = 10;
      const totalX = width - 70; // Adjust as needed
      doc.setFontSize(totalTextSize);
      doc.text(
        `Total Saving Amount (ETB):  ${invoiceData.totalAmount}`,
        totalX,
        doc.autoTable.Previous.finalY + 20
      );
    }
    // Save the PDF and initiate download
    doc.save("invoice.pdf");
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div className="card-header cursor-pointer">
              <div className="card-body p-9">
                <div
                  id="kt_account_settings_profile_details"
                  className="collapse show"
                >
                  <form
                    onSubmit={(e) => FundTransferRequired(e)}
                    id="kt_account_profile_details_form"
                    className="form"
                  >
                    <div className="card-body border-top p-9">
                      <div className="row mb-8">
                        <div className="col-lg-12 mb-1">
                          <div className="row">
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                              Amount (ETB)
                            </label>
                            <div className="col-lg-8 fv-row">
                              <input
                                type="number"
                                name="amount"
                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                placeholder="Amount"
                                step="any"
                                required
                                value={WithAmount}
                                onChange={(e) => setWithAmount(e.target.value)}
                                min={0}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 mb-8">
                        <div className="row">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Share Amount (ETB)
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="share am"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Amount"
                              step="any"
                              required
                              value={(WithAmount * 20) / 100}
                              onChange={(e) => setWithAmount(e.target.value)}
                              min={0}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mb-8">
                        <div className="row">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Total Saving Amount (ETB)
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="saving am"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Amount"
                              step="any"
                              required
                              value={WithAmount - (WithAmount * 20) / 100}
                              onChange={(e) => setWithAmount(e.target.value)}
                              min={0}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-light btn-active-light-primary me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Recent Transactions
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7"></span>
              </h3>
              <div className="text-end">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn  btn-sm btn-SUCCESS "
                  table="VoluntaryToMandatory-table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText={
                    <>
                      <i className="fad fa-download fs-1"></i>
                    </>
                  }
                />
              </div>
            </div>

            <div className="card-body py-3">
              <div className="table-responsive">
                <table
                  table="VoluntaryToMandatory-table-to-xls"
                  className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                >
                  <thead>
                    <tr className="fw-bold text-muted th-title">
                      <th className="min-w-125px">
                        Transaction <br /> Date{" "}
                      </th>
                      <th className="min-w-150px">
                        Total
                        <br />
                        Amount (ETB)
                      </th>
                      <th className="min-w-150px">
                        Share
                        <br />
                        Amount (ETB)
                      </th>
                      <th className="min-w-150px">
                        Saving <br /> Type{" "}
                      </th>

                      <th className="min-w-150px">
                        Status <br />
                      </th>
                      <th className="min-w-150px">
                        Cancel <br />
                      </th>
                      {/* <th className="min-w-100px text-center">
                        Invoice <br />
                      </th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {VolandManDetails.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {moment(item.createdAt).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </td>
                        <td>{item.totalAmount}</td>
                        <td>{item.shareAmount}</td>
                        <td>{item.to}</td>

                        <td>
                          {item.status == "cancel" ? (
                            <span className="text-danger text-center">
                              Cancelled
                            </span>
                          ) : (
                            <span className="text-success text-center">
                              Success
                            </span>
                          )}
                        </td>

                        <td>
                          <button
                            onClick={(e) => {
                              Transitioncancel(item);
                            }}
                            className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-trigger="hover"
                            title="Cancel"
                          >
                            <span className="svg-icon svg-icon-3">
                              <img
                                src="/assets_new/images/cross.png"
                                width="20px"
                              />
                            </span>
                          </button>
                        </td>
                        <td className="text-center">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => DownloadInvoice(item)}
                          >
                            Download Invoice
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-lg-12 mt-2 text-end">
                <ReactPaginate
                  PreviousLabel={"Previous"}
                  NextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={Page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  //   onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-end gap-sm-3"}
                  pageClassName={"page-item mx-2"}
                  pageLinkClassName={"page-link"}
                  PreviousClassName={"page-item"}
                  PreviousLinkClassName={"page-link pagestyle"}
                  NextClassName={"page-item"}
                  NextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <OrgVerifyModel
        ShowModel={ShowModel}
        setShowModel={setShowModel}
        handleSubmit={FundTransferor}
      />
      <OrgVerifyModel
        ShowModel={ShowCancelModel}
        setShowModel={setShowCancelModel}
        handleSubmit={handleCancelTransaction}
      />
    </>
  );
}

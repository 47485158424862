import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomerLoader from "../../../SuperAgent/CRM/Modal/CustomerLoder";

function UpdateAccountSuperAgent({ hadlersuper }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  console.log({ state });
  const [formData, setFormData] = useState({
    accountName: "",
    accountType: "",
    subAccount: "",
    accountCode: "",
    accountBalance: 0,
    accountId: "",
    openingBalance: 0,
  });

  useEffect(() => {
    setFormData({
      accountName: state?.accountName,
      accountType: state?.accountType,
      subAccount: state?.subAccount,
      accountCode: state?.accountCode,
      accountBalance: state?.accountBalance,
      accountId: state?._id,
      openingBalance: parseInt(state?.openingBalance),
    });
  }, [state]);

  const [showLoader, setShowLoader] = useState(false);
  const [CategoryData, setCategoryData] = useState([]);

  const token = localStorage.getItem("SuperAgentChromepayToken");

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "openingBalance" ? parseFloat(value) || 0 : value, 
    }));
  };

  // Submit form data
  const UpdateAccount = async (e) => {
    e.preventDefault();
    setShowLoader(true);

    try {
      const response = await axios.post(
        `/v1/super-Agent/update-accounts`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { data } = response;
      if (data.status) {
        toast.success(data.msg);
        navigate("/super-agent-account-list");
      } else {
        toast.error(data.msg);
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const CategoryList = async (e) => {
    try {
      const response = await axios.post(
        `/v1/super-Agent/category-list`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { data } = response;

      console.log("category list", data?.data?.categories);

      let categories = data?.data?.categories || [];

      if (data.status) {
        setCategoryData(categories);
      } else {
        toast.error(data.msg);
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    hadlersuper();
    CategoryList();
  }, []);

  return (
    <>
      <ToastContainer position="top-right" />
      <CustomerLoader loader={showLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Details Account</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={UpdateAccount}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="row">
                          {/* Account Name */}
                          <div className="col-md-6 mb-6">
                            <label className="form-label fw-semibold fs-6">
                              Account Name
                            </label>
                            <input
                              type="text"
                              name="accountName"
                              value={formData.accountName}
                              onChange={handleChange}
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Enter Account Name"
                              readOnly
                            />
                          </div>

                          {/* Account Type */}

                          <div className="col-md-6 mb-6">
                            <label className="form-label fw-semibold fs-6">
                              Account Type
                            </label>
                            <select
                              name="accountType"
                              value={formData.accountType}
                              onChange={handleChange}
                              className="form-control form-control-lg form-control-solid"
                              readOnly
                            >
                              <option value="" disabled>
                                Select Account Type
                              </option>
                              {CategoryData?.map((item) => (
                                <option value={item?._id}>{item?.title}</option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row">
                          {/* Sub Account */}
                          <div className="col-md-6 mb-6">
                            <label className="form-label fw-semibold fs-6">
                              Sub Account
                            </label>

                            <select
                              name="subAccount"
                              value={formData.subAccount}
                              onChange={handleChange}
                              className="form-control form-control-lg form-control-solid"
                              readOnly
                            >
                              <option value="" disabled>
                                Select Sub Account
                              </option>
                              <option value="Bank">Bank</option>
                              <option value="Cash">Cash</option>
                              <option value="Fixed Asset">Fixed Asset</option>
                              <option value="Receivable">Receivable</option>
                              <option value="Payable">Payable</option>
                              <option value="Equity">Equity</option>
                              <option value="Revenue">Revenue</option>
                              <option value="Expense">Expense</option>
                            </select>
                          </div>

                          {/* Account Code */}
                          <div className="col-md-6 mb-6">
                            <label className="form-label fw-semibold fs-6">
                              Account Code
                            </label>
                            <input
                              type="text"
                              name="accountCode"
                              value={formData.accountCode}
                              onChange={handleChange}
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Enter Account Code"
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 mb-6">
                            <label className="form-label fw-semibold fs-6">
                              Opening Balance
                            </label>
                            <input
                              type="number"
                              name="openingBalance"
                              value={formData.openingBalance}
                              onChange={handleChange}
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Enter Account Balance"
                              required
                              min={0}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button type="submit" className="btn btn-primary">
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateAccountSuperAgent;

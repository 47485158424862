import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

export default function Sidebar() {
  let orgAgentType = localStorage.getItem("orgAgentType");

  const customElementRef = useRef(null);
  const customElementRef1 = useRef(null);

  const [isClicked, setIsClicked] = useState(false);
  const [checkbank, setcheckbank] = useState(true);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  useEffect(() => {
    let orgAgentType = localStorage.getItem("orgAgentType");
    console.log({ orgAgentType });
    if (orgAgentType == "Union") {
      setcheckbank(false);
    } else if (orgAgentType == "Cooperative") {
      setcheckbank(false);
    } else {
      setcheckbank(true);
    }
  }, []);

  const toggleMobileView = () => {
    // Access the DOM elements using refs
    const element = customElementRef.current;
    const element1 = customElementRef1.current;

    // Check if the elements are available
    console.log("Element:", element);
    console.log("Element1:", element1);

    // Toggle the class if elements are available
    if (element) {
      element.classList.add("custom-fixed-element");
    }

    if (element1) {
      element1.classList.add("show");
      element1.classList.add("menu-dropdown");
    }
  };

  return (
    <>
      <div
        id="kt_aside"
        className="aside bg-primary "
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="auto"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_toggle"
      >
        <div
          className="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-8"
          id="kt_aside_logo"
        >
          <Link to="/organization">
            <img
              alt="Logo"
              src="/assets_new/images/logo.png"
              className="h-50px"
            />
          </Link>
        </div>

        <div
          className="aside-nav d-flex flex-column align-lg-center flex-column-fluid w-100 pt-5 pt-lg-0 h-50 "
          id="kt_aside_nav"
        >
          <div
            // drl
            className="hover-scroll-overlay-y my-2 my-lg-5 "
            id="kt_aside_menu_wrapper"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu"
            data-kt-scroll-offset="5px"
          >
            <div
              id="kt_aside_menu"
              className="menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-6"
              data-kt-menu="true"
            >
              <div className="menu-item here show py-2">
                <Link to="/organization" className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i className="fad fa-home-lg fs-1"></i>
                  </span>
                </Link>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i className="far fa-user-plus fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px showlist ">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        CRM
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/customer-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                        Active {checkbank ? "Union IDs" : "Bruh IDs"}
                      </span>
                    </Link>
                    {/* <Link to="/AddCustomerdemo" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Create Digital ID</span>
                    </Link> */}

                    <Link to="/OrgDigitalId" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                        {checkbank ? "Union IDs" : "Bruh IDs"} Awaiting Approval
                      </span>
                    </Link>
                    <Link to="/OrgcustomerBlocklist" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                        Blocked {checkbank ? "Union IDs" : "Bruh IDs"}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <div className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    {/* <i class="fas fa-file-chart-line fs-1"></i> */}
                    <i
                      class="fas fa-file-chart-line"
                      style={{ fontSize: "1.8rem" }}
                    ></i>
                  </span>
                </div>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px showlist1">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Reports
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/OrdAgentReportView" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Agent Reports</span>
                    </Link>

                    {/* <Link to="/agent-report" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                        Agents Performance Report
                      </span>
                    </Link> */}
                    <Link to="/Orgselfreport" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Organisation Reports</span>
                    </Link>
                    <Link
                      to="/superagent/super-agent-report-org"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Super Agent Report</span>
                    </Link>
                    {/* <div className="menu-item menu-accordion">
                      <Link to="/transtion-list" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Transaction Reports</span>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
                onClick={() => toggleMobileView()}
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    {/* <i class="fas fa-users-medical fs-1"></i> */}
                    <i class="fas fa-university fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px ashow2">
                  <div className="menu-item">
                    <div className="menu-content">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Organisation Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <div className="menu-link menu-title">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <div
                        ref={customElementRef1}
                        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                        data-kt-menu-placement="left-start"
                        className="menu-item py-2"
                        // onClick={() => toggleMobileView()}
                      >
                        <span className="menu-title">
                          Administration{" "}
                          <i class="angel-arrow fal fa-angle-right"></i>
                        </span>

                        <div
                          ref={customElementRef}
                          className=" menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px changeposition"
                        >
                          <div className="menu-item menu-accordion">
                            <Link to="/Createadmin" className="menu-link">
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                              </span>
                              <span className="menu-title"> Create Admin</span>
                            </Link>

                            <Link to="/Viewadmin" className="menu-link">
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                              </span>
                              <span className="menu-title">View Admins</span>
                            </Link>
                            <Link
                              to="/admin/unblock/list-org"
                              className="menu-link"
                            >
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                              </span>
                              <span className="menu-title">Blocked Admin</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Link to="/EmpolyeesRegister" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Register Employees</span>
                    </Link>
                    <Link to="/EmpolyListORG" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Employees </span>
                    </Link>
                    <Link to="/employee/unblock/list-org" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Employee Blocked List </span>
                    </Link>
                  </div>
                </div>
              </div>

         

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    {/* <i class="fas fa-users-slash fs-1"></i> */}
                    <i class="fad fa-download fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Export Files
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/Export-customer-export" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Users CSV</span>
                    </Link>
                    {/* <Link to="/Export-customer-export" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Organisation Customer list</span>
                    </Link> */}
                    <Link to="/Exportorgblocklist" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Blocked Users CSV</span>
                    </Link>
                    <Link to="/Export-agent-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Agents CSV </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    {/* <i class="fas fa-users-slash fs-1"></i> */}
                    <i class="fas fa-users-class fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Agent Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/agent-add" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Add Agent</span>
                    </Link>
                    <Link to="/agent-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Agent List</span>
                    </Link>
                    <Link to="/Blockagentlist" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Blocked Agents</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    {/* <i class="fas fa-users-slash fs-1"></i> */}
                    <i class="far fa-chalkboard-teacher fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Super Agent Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/super-agent-add" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Add Super Agent</span>
                    </Link>
                    <Link to="/super-agent-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Super Agent List</span>
                    </Link>
                    <Link to="/super-agent-block" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Blocked Super Agents</span>
                    </Link>
                    {/* <Link to="/super-agent-wallet" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Wallet Managment</span>


                    </Link> */}
                    {/* <Link to="/superagent/loan-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Super Agent Loan List</span>


                    </Link> */}
                    {/* <Link to="/superagent/super-agent-report-org" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Super Agent Report</span>


                    </Link> */}
                  </div>
                </div>
              </div>
              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i class="fad fa-sack-dollar fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist4">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Loan Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/loan-apply-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Loan Applications</span>
                    </Link>
                    <Link to="/loan-pass" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Approved Loans</span>
                    </Link>
                    <Link to="/admin-blocked-export" className="menu-link">

                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Blocked DIDs</span>
                                    </Link>
                  </div>
                </div>
              </div> */}
              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    {/* <i class="fas fa-users-slash fs-1"></i> */}
                    {/* <i class="fas fa-sign-in fs-1"></i> */}
                    <i class="fal fa-columns fs-1"></i>
                    {/* <i class="fad fa-download fs-1"></i> */}
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist5">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Logs Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/agent-logs-links" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Agent Logs</span>
                    </Link>
                    <Link
                      to="/superagent/super-agent-logs"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Super Agent Logs</span>
                    </Link>
                    <Link to="/user-logs-org" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">D-ID User Logs</span>
                    </Link>
                    {/* <Link to="/admin-blocked-export" className="menu-link">

                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Blocked DIDs</span>
                                    </Link> */}
                  </div>
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i className="fas fa-university fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Loan Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    {/* <Link to="/Super-Agent-pendingloan" className="menu-link"> */}
                    <Link
                      to="/Org/superAgent-loan-apply-list"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                        Pending Loan Applications
                      </span>
                    </Link>
                    <Link
                      to="/Org/superAgent-loan-approve-list"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                        Approve Loan Applications
                      </span>
                    </Link>
                    {/* <Link to="/Super-Agent-pendingloan" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Approved Loans </span>
                      </Link> */}
                    {/* <Link to="/Super-Agent-Share-Apply-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Approved Share </span>


                    </Link> */}

                    <Link
                      to="/Org/superAgent-loan-Reject-list"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Rejected Loans </span>
                    </Link>

                    {/* <Link
                        to="/Super-agent/Emi-logs-list"
                        className="menu-link"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title"> EMI Logs </span>
                      </Link> */}
                  </div>
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i class="fas fa-analytics fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Share Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link
                      to="/Org/superAgent-share-apply-list"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                        Pending Share Applications
                      </span>
                    </Link>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link
                      to="/Org/superAgent-Withdrawal-apply-list"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                        Withdrawal Share Applications
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i class="fab fa-sellcast fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Storefront Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link
                      to="/Org/products/ManagementList"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Product Management</span>
                    </Link>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/Org/Super-Agent/Ordeals" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Orders List</span>
                    </Link>
                  </div>
                </div>
              </div>

              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              > */}
              {/* <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                  
                    <i class="far fa-key-skeleton fs-1"></i> 
                   
                  </span>
                </span> */}

              {/* <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist5">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                      Security Management
                      </span>
                    </div>
                  </div> */}

              {/* <div className="menu-item menu-accordion"> */}
              {/* <Link to="/agent-logs-links" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Agent Logs List</span>
                    </Link> */}
              {/* <Link to="/orgnization-logs-list" className="menu-link">

                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Organzation Logs List</span>
                                    </Link> */}
              {/* <Link to="/admin-blocked-export" className="menu-link">

                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">Blocked DIDs</span>
                                    </Link> */}
              {/* </div>
                </div> */}
              {/* </div> */}
              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                  
                    <i class="far fa-user-minus fs-1"></i>
                 
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px">
                  <div className="menu-item">
                    <div className="menu-content">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Notes Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/OrgBlocklist" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Block User List</span>
                    </Link>
                    <Link to="/Orgdeleteuse" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Delete User List</span>
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div
          className="aside-footer d-flex flex-column align-items-center flex-column-auto"
          id="kt_aside_footer"
        >
          <div className="mb-7">
            <button
              type="button"
              className="btn btm-sm btn-custom btn-icon icon"
              onClick={handleClick}
            >
              <i className="fonticon-setting fs-1"></i>
            </button>

            <div
              className={
                isClicked
                  ? "dropdown-profile menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px showlist6 show"
                  : "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px  dropdown-profile showlist6"
              }
              data-kt-menu="true"
            >
              <div className="menu-item px-3">
                <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">
                  Profile Setting
                </div>
              </div>

              <div className="separator mb-3 opacity-75"></div>

              <div className="menu-item px-3">
                <Link
                  to="/my-profile-org"
                  className="menu-link px-3"
                  onClick={handleClick}
                >
                  My Profile
                </Link>
              </div>
              <div className="menu-item px-3">
                <Link
                  to="/org-change-password"
                  className="menu-link px-3"
                  onClick={handleClick}
                >
                  Password and OTP
                </Link>
              </div>

              {/* <div className="menu-item px-3">
                <Link to="/org-change-password" className="menu-link px-3" onClick={handleClick}>
                  Account Settings
                </Link>
              </div> */}
              <div className="menu-item px-3">
                <Link
                  to="/remaning-license"
                  className="menu-link px-3"
                  onClick={handleClick}
                >
                  Request License
                </Link>
              </div>
              <div className="menu-item px-3">
                <Link
                  to="/Share-updated-org"
                  className="menu-link px-3"
                  onClick={handleClick}
                >
                  Share Information
                </Link>
              </div>
              {/* <div className="menu-item px-3">
                <Link to="/AgentOrgTransactionlimit" className="menu-link px-3" onClick={handleClick}>
                  Agent Transaction Limit
                </Link>
              </div> */}
              {/* <div className="menu-item px-3">
                <Link to="/organization" className="menu-link px-3" onClick={handleClick}>
                  Fee Setup
                </Link>
              </div> */}
              <div className="separator mb-3 opacity-75"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import Swal from "sweetalert2";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import CustomerLoader from "../../../SuperAgent/CRM/Modal/CustomerLoder";
var jwt = require("jsonwebtoken");

function CategoryListAdmin({ hadleradmin }) {
  const navigate = useNavigate();
  let token = localStorage.getItem("ChromePayAdmitToken");
  var decode1 = jwt.decode(token);
  const [data, setData] = useState([]);
  const [ShowLoader, setShowLoader] = useState(false);
  const [pageCount, setpageCount] = useState(1);

  let limit = 10;

  const AddFormData = async (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);
    axios
      .post(`/v1/super-Agent/category-list`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data?.data;
        setData(data?.categories || []);
        const total = resp?.data?.totalCategories;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        console.log("customer info", data);
        setShowLoader(false);
      });
  };

  const CategoryList = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/category-list`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        if (resp?.data?.status) {
          const data = resp.data?.data;
          setData(data?.categories || []);
          const total = data?.totalCategories;
          const totalPage = Math.ceil(total / limit);
          setpageCount(totalPage);
          setShowLoader(false);
        } else {
          // setShowLoader(false);
        }
      });
  };

  const handleDelete = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete selected Category ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `/v1/super-Agent/remove-accounts-category`,
            { categoryId: _id },

            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((res) => {
            if (res.status) {
              let data = res.data;
              if (data.status) {
                Swal.fire(
                  "Deleted!",
                  "Customer deleted successfully",
                  "success"
                );
                return CategoryList();
              } else {
                toast.error(data.msg);
              }
            } else {
              toast.error(data.msg);
            }
          });
      }
    });
  };

  useEffect(() => {
    CategoryList();
    hadleradmin();
  }, []);

  const fetchComments = async (page) => {
    const senData = { page: page };
    axios
      .post(`/v1/super-Agent/account-list`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        if (resp?.data?.status) {
          const data = resp.data?.data;
          setData(data?.categories || []);
          const total = data?.totalCategories;
          const totalPage = Math.ceil(total / limit);
          setpageCount(totalPage);
          setShowLoader(false);
        } else {
          // setShowLoader(false);
        }
      });
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        Category
                      </span>
                    </div>

                    <div className="d-flex flex-column">
                      <div className="d-lg-flex align-lg-items-center">
                        <form onSubmit={(e) => AddFormData(e)}>
                          <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                            <div className="row flex-grow-1 mb-5 mb-lg-0">
                              <div className="col-lg-6 d-flex input-container align-items-center mb-3  mb-lg-0">
                                <label className="small_label">
                                  Start Date
                                </label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <i className="fad fa-calendar fs-2"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush flex-grow-1"
                                  name="fromDate"
                                  placeholder="from Date.."
                                />
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                              </div>

                              <div className="col-lg-6 d-flex align-items-center mb-3 input-container mb-lg-0">
                                <label className="small_label">End Date</label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <i className="fad fa-calendar fs-2"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush flex-grow-1"
                                  name="toDate"
                                  placeholder="to Date.."
                                />
                              </div>
                            </div>

                            <div className="min-w-150px d-flex">
                              <button
                                type="submit"
                                className="btn btn-dark"
                                id="kt_advanced_search_button_1 me-5"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={CategoryList}
                                className="btn btn-secondary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Category List
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                    <div className="text-end">
                      <button
                        type="button"
                        className="download-table-xls-button btn btn-success mb-3"
                        onClick={() => navigate("/admin/add-category")}
                      >
                        Add Category
                      </button>
                    </div>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table
                        className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">Title</th>
                            <th className="min-w-125px">Description</th>

                            <th className="min-w-100px">
                              Date of Registration
                            </th>
                            <th className="min-w-100px">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((item) => (
                            <tr>
                              <td className="text-center"> {item?.title} </td>
                              <td className="text-center">
                                {" "}
                                {item?.description}{" "}
                              </td>

                              <td className="text-center">
                                {Moment(item?.createdAt).format("DD/MM//YYYY")}
                              </td>

                              <td>
                                <div className="d-flex justify-content-center flex-shrink-0">
                                  <button
                                    type="button"
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="edit "
                                    onClick={() =>
                                      navigate("/admin/update-category", {
                                        state: item,
                                      })
                                    }
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fas fa-edit fs-2"></i>
                                    </span>
                                  </button>

                                  <button
                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Delete"
                                    onClick={() => handleDelete(item?._id)}
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="/assets_new/images/cross.png"
                                        width="20px"
                                      />
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mt-2 text-end">
                  <ReactPaginate
                    PreviousLabel={"Previous"}
                    NextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-end gap-1"}
                    pageClassName={"page-item mx-2"}
                    pageLinkClassName={"page-link"}
                    PreviousClassName={"page-item"}
                    PreviousLinkClassName={"page-link pagestyle"}
                    NextClassName={"page-item"}
                    NextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryListAdmin;
